import React from 'react';
import PropTypes from 'prop-types';

import Editor from '../EditorAce';

function CodeEditor({
  playerId,
  editorIndex,
  code = '',
  language,
  handleCodeChange,
  height,
  width,
  readOnly,
  activeIndex
}) {
  return (
    <Editor
      playerId={playerId}
      editorIndex={editorIndex}
      code={code}
      language={language.toLowerCase()}
      handleCodeChange={handleCodeChange}
      height={height}
      width={width}
      readOnly={readOnly}
      activeIndex={activeIndex}
    />
  );
}

CodeEditor.propTypes = {
  /**
   * The id of the player for this window.
   */
  playerId: PropTypes.number.isRequired,
  /**
   * Provide the index of this player.
   */
  code: PropTypes.string.isRequired,
  /**
   * Provide the index of this player.
   */
  language: PropTypes.string.isRequired,
  /**
   * A handler for code changes
   */
  handleCodeChange: PropTypes.func
};

export default CodeEditor;
