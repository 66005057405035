import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@material-ui/core/SvgIcon";

import { socWhite1 } from "../../style/soc-colors";

const TickIcon = props => (
  <SvgIcon {...props}>
    <path d="M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M26,50C12.767,50,2,39.233,2,26   S12.767,2,26,2s24,10.767,24,24S39.233,50,26,50z" />
    <path d="M38.252,15.336l-15.369,17.29l-9.259-7.407c-0.43-0.345-1.061-0.274-1.405,0.156c-0.345,0.432-0.275,1.061,0.156,1.406   l10,8C22.559,34.928,22.78,35,23,35c0.276,0,0.551-0.114,0.748-0.336l16-18c0.367-0.412,0.33-1.045-0.083-1.411   C39.251,14.885,38.62,14.922,38.252,15.336z" />
  </SvgIcon>
);

TickIcon.propTypes = {
  color: PropTypes.string,
  viewBox: PropTypes.string
};
TickIcon.defaultProps = {
  color: socWhite1,
  viewBox: "0 0 52 52"
};

export default TickIcon;
