import React, { Component } from 'react';
import { Redirect, withRouter, Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { actions, selectors } from '@school-of-code/soc-redux';

import LoginForm from '../LoginForm';
import css from '../Page.module.css';

const { meta: MetaActions } = actions;
const {
  meta: { selectIsAuthenticated }
} = selectors;

class LoginPage extends Component {
  //TODO: need to reduxify this auth workflow
  //TODO: https://auth0.com/blog/secure-your-react-and-redux-app-with-jwt-authentication/
  render() {
    const {
      registerUrl,
      resetUrl,
      isAuthenticated,
      handleSubmitForm,
      metaError
    } = this.props;

    const { from } = this.props.location.state || { from: { pathname: '/' } };

    if (isAuthenticated) {
      return <Redirect to={from} />;
    }

    return (
      <Paper className={css.container} elevation={1}>
        <LoginForm onSubmit={handleSubmitForm} metaError={metaError} />
        <hr />
        <div className={css.footnote}>
          Not got an account yet? <Link to={registerUrl}>Register</Link>
          <span className={css.separator}>|</span>
          Forgot your password? <Link to={resetUrl}>Reset</Link>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    isAuthenticated: selectIsAuthenticated(state),
    metaError: state.meta.metaError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleSubmitForm: creds => {
      dispatch(MetaActions.loginUser(creds));
    },
    dispatch
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginPage)
);
