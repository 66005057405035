import React from 'react';
import { Link } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import { constants } from '@school-of-code/soc-redux';

import Form from '../RootAuthForm';
import css from '../Page.module.css';

const {
  AuthFormTypes: { REGISTER }
} = constants;

export default ({ loginUrl }) => (
  <Paper className={css.container} elevation={1}>
    <Form type={REGISTER} />
    <hr />
    <div className={css.footnote}>
      Already registered? <Link to={loginUrl}>Login</Link>
    </div>
  </Paper>
);
