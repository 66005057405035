import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from '@material-ui/core/Button';

import PlayIcon from '@material-ui/icons/PlayArrow';

import ChatWindowContainer from '../../../containers/ChatWindowContainer';

import { constants } from '@school-of-code/soc-redux';

import css from './PlayerFooter.module.css';
const {
  PlayerStatuses,
  CodeLanguages: { JS },
  ChallengeData: { CHARACTER_BUILDING_TASK }
} = constants;

const PlayerFooter = ({
  playerStatus,
  isFinalTask,
  test,
  combinedPlayerStatus,
  taskName,
  userInputs,
  saveButtonEnabled,
  handleHintClick,
  handleRunClick,
  handleSubmitClick,
  handleSkipClick,
  handleNextClick,
  handleSaveClick,
  handleLoadClick
}) => {
  const inputTypes = userInputs.map(({ type }) => type);
  const jsTask = inputTypes.includes(JS.type);
  let buttons = [];

  // if (isFinalTask && taskName !== CHARACTER_BUILDING_TASK) {
  //   return <div className="area-heading player_footer"></div>;
  // }

  if (taskName !== CHARACTER_BUILDING_TASK) {
    if (jsTask) {
      buttons.push(
        <Button
          variant="contained"
          className={cn(css.playerFooterRunButton, css.run)}
          // hoverColor={socPositiveGreenMuted} NOTE: put this in css
          onClick={handleRunClick}
        >
          Run <PlayIcon />
        </Button>
      );
      if (
        !isFinalTask &&
        (playerStatus === PlayerStatuses.PASSED ||
          playerStatus === PlayerStatuses.DONE)
      ) {
        buttons.push(
          <Button
            className={css.submit}
            // hoverColor={socPositiveGreen} NOTE
            onClick={handleSubmitClick}
          >
            Submit
          </Button>
        );
      }
    }
    if (!isFinalTask) {
      if (combinedPlayerStatus === PlayerStatuses.DONE || !test) {
        buttons.push(
          <Button variant="contained" onClick={handleNextClick}>
            Next
          </Button>
        );
        buttons.push(
          <Button color="secondary" onClick={handleSkipClick}>
            Skip
          </Button>
        );
      }
      if (test) {
        buttons.push(
          <Button color="secondary" onClick={handleSkipClick}>
            Skip
          </Button>
        );
      }
    }
  }
  return (
    <div
      className={cn(css.areaHeading, css.playerFooter, css.playerFooterCentred)}
    >
      <div>
        <Button
          variant="contained"
          className={css.save}
          onClick={handleSaveClick}
          disabled={!saveButtonEnabled}
        >
          Save
        </Button>
        <Button
          variant="contained"
          className={css.load}
          onClick={handleLoadClick}
          disabled={!saveButtonEnabled}
        >
          Load
        </Button>
      </div>

      {buttons.map((a, i) => (
        <div key={i}>{a}</div>
      ))}
      <div className={css.right}>
        <ChatWindowContainer />
      </div>
    </div>
  );
};
PlayerFooter.propTypes = {
  playerStatus: PropTypes.string,
  isFinalTask: PropTypes.bool,
  combinedPlayerStatus: PropTypes.string,
  handleHintClick: PropTypes.func,
  handleRunClick: PropTypes.func,
  handleSubmitClick: PropTypes.func,
  handleSkipClick: PropTypes.func,
  handleNextClick: PropTypes.func
};
PlayerFooter.defaultProps = {};

export default PlayerFooter;
