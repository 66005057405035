import React from "react";
import { connect } from "react-redux";

import CharacterChooserModal from "../CharacterChooserModal";
import FileChooserModal from "../FileChooserModal";

import { actions, selectors } from "@school-of-code/soc-redux";
const { modals: ModalsActions } = actions;
const {
  game: { selectThisPlayerName, selectThisPlayerId },
  challenge: { selectChallengeName }
} = selectors;

const setChooserModal = onSubmit => {
  const BaseChooserModal = ({
    onClose,
    onSubmit,
    onError,
    onChange,
    options,
    user,
    chosen,
    error,
    inputError,
    optionsType,
    filter,
    onSelect,
    modalType
  }) =>
    optionsType === "character" ? (
      <CharacterChooserModal
        onClose={onClose}
        onSubmit={onSubmit}
        onChange={onChange}
        options={options}
        user={user}
        chosen={chosen}
        onError={onError}
        error={error}
        inputError={inputError}
        filter={filter}
        onSelect={onSelect}
        modalType={modalType}
      />
    ) : (
      <FileChooserModal
        onClose={onClose}
        onSubmit={onSubmit}
        onChange={onChange}
        onSelect={onSelect}
        options={options}
        user={user}
        chosen={chosen}
        onError={onError}
        error={error}
        inputError={inputError}
        filter={filter}
        type={optionsType}
        modalType={modalType}
      />
    );

  const mapStateToProps = (state, ownProps) => {
    return {
      thisPlayerId: selectThisPlayerId(state),
      //TODO: temp hack, and should these two be in props anyway?
      user: selectThisPlayerName(state),
      challenge: selectChallengeName(state),
      filter: state.modals.modalProps.filter,
      modalType: state.modals.modalType,
      ...ownProps
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      onChange: text => dispatch(ModalsActions.filterCharacters(text)),
      onSelect: text => dispatch(ModalsActions.setChosen(text)),
      dispatch
    };
  };

  const mergeProps = (stateProps, dispatchProps) => {
    const { dispatch } = dispatchProps;
    return {
      ...stateProps,
      ...dispatchProps,
      onClose: () => {
        dispatch(ModalsActions.hideModal());
      },
      onSubmit: chosen => onSubmit(chosen, dispatch, stateProps),
      onError: err => {
        dispatch(ModalsActions.handleError(err));
      }
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(BaseChooserModal);
};

export default setChooserModal;
