import React from 'react';
import css from './RenderIframe.module.css';

function renderIFrame(WrappedComponent, renderContent) {
  return function(props) {
    const content = renderContent(props);
    const blob = new Blob([content], { type: 'text/html' });
    const src = URL.createObjectURL(blob);

    return (
      <WrappedComponent {...props}>
        <iframe
          className={css.previewWindowWebFrame}
          title="SoC iFrame"
          src={src}
        />
      </WrappedComponent>
    );
  };
}

export default renderIFrame;
