import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { actions, selectors } from '@school-of-code/soc-redux';
const { modals: ModalsActions } = actions;
const {
  game: { selectThisPlayerCurrentInfo }
} = selectors;

const HintModal = ({ onClose, hintText }) => {
  const dialogContent = hintText ? (
    <pre>{hintText}</pre>
  ) : (
    <p>No hints available</p>
  );

  return (
    <Dialog title="Hint" open onClose={onClose}>
      {dialogContent}
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function mapStateToProps(state, ownProps) {
  //TODO: this can probably be passed to modalProps from the action, if it gets sorted
  let hintText;
  const playerInfo = selectThisPlayerCurrentInfo(state);
  if (playerInfo) {
    hintText = playerInfo.solution[0];
  }

  return {
    hintText,
    ...ownProps
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps) => {
  const { dispatch } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    onClose: () => {
      dispatch(ModalsActions.hideModal());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(HintModal);
