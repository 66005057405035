import React from 'react';

import cn from 'classnames';
import css from '../ChatWindow.module.css';

export default ({ playerName, playerId, thisPlayerId, children }) => {
  const messageClass = cn(css.chatWindowMessage, {
    [css.chatWindowMessageMe]: playerId === thisPlayerId
  });
  return (
    <div className={messageClass}>
      <h4>{playerName}</h4>
      <span className={css.messageText}>{children}</span>
    </div>
  );
};
