import React from 'react';
import PropTypes from 'prop-types';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-twilight';

const opts = {
  editorProps: {
    $blockScrolling: 'Infinity'
  },
  setOptions: {
    useWorker: false,
    behavioursEnabled: false
  }
};

function EditorAce({
  playerId,
  editorIndex,
  activeIndex,
  code = '',
  language,
  onFocus,
  onBlur,
  handleCodeChange,
  height,
  readOnly
}) {
  const handleCodeChangeOverride = code =>
    handleCodeChange && handleCodeChange(code, editorIndex);

  return (
    <AceEditor
      mode={language}
      theme="twilight"
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={handleCodeChangeOverride}
      value={code}
      name={`editor-${playerId}-${editorIndex}`}
      width="100%"
      height={height}
      focus={editorIndex === activeIndex}
      editorProps={opts.editorProps}
      setOptions={opts.setOptions}
      readOnly={readOnly}
    />
  );
}

EditorAce.propTypes = {
  /**
   * The id of the player for this editor.
   */
  playerId: PropTypes.number.isRequired,
  /**
   * The index for this editor.
   */
  editorIndex: PropTypes.number.isRequired,
  /**
   * The code in the window
   */
  code: PropTypes.string.isRequired,
  /**
   * The language for this task
   */
  language: PropTypes.string.isRequired,
  /**
   * Handler for getting focus
   */
  onFocus: PropTypes.func,
  /**
   * Handler for losing focus
   */
  onBlur: PropTypes.func,
  /**
   * A handler for code changes
   */
  handleCodeChange: PropTypes.func
};

export default EditorAce;
