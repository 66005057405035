const parser = new DOMParser();
const serializer = new XMLSerializer();
const singleImportWidth = "80%"; //could pass this as props..

const renderContent = ({ imports, userInputs }) => {
  let codeMap = {
    html: "",
    css: "",
    js: ""
  };

  let bodyStyle = "padding:0;margin:0;";

  imports.forEach(({ type, content }) => {
    if (!content) {
      return;
    }
    switch (type) {
      case "svg":
        bodyStyle += "text-align:center;";

        const importWidthPercent =
          imports.length === 1
            ? singleImportWidth
            : 100 / imports.length - 1 + "%";

        codeMap.html = imports
          .map(anImport => {
            const svgDoc = parser.parseFromString(anImport, "application/xml");
            const svgElement = svgDoc.getElementsByTagName("svg")[0];
            if (svgElement) {
              svgElement.setAttribute("width", importWidthPercent);
              return serializer.serializeToString(svgElement);
            }
            return anImport;
          })
          .reduce((prev, curr) => prev + curr);
        break;
      default:
        codeMap[type] += content;
    }
  });

  codeMap = userInputs.reduce((acc, input) => {
    const { type, content } = input;
    if (!content) {
      return acc;
    }
    return { ...acc, [type]: (acc[type] += content) };
  }, codeMap);

  return `<html><head><style>${
    codeMap.css
  }</style></head><body style=${bodyStyle}>${codeMap.html}<script>${
    codeMap.js
  }</script></body></html>`;
};

export default renderContent;
