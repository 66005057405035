import React from 'react';
import PropTypes from 'prop-types';
import css from './PlayerName.module.css';

const PlayerName = ({ name, fontSize, color, isShown }) => {
  const style = {
    color,
    fontSize: fontSize,
    visibility: isShown
  };

  return (
    <div className={css.container} style={style}>
      <h1 className={css.name}>{name}</h1>
    </div>
  );
};

PlayerName.propTypes = {
  name: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  color: PropTypes.string,
  isShown: PropTypes.string
};

PlayerName.defaultProps = {
  name: 'Player Name',
  color: '#181818',
  fontSize: '20px',
  isShown: 'visible'
};

export default PlayerName;
