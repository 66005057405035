import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import css from './panel.module.css';

const cardStyles = { padding: '2px', backgroundColor: 'rgba(2,168,243,0.2)' };

const ExpandableCard = ({ title, children }) => {
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={css.text}>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={cardStyles}>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default ExpandableCard;
