import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import UserIcon from '@material-ui/icons/Person';

import Autosuggest from 'react-autosuggest';

import { sendRequest } from '../../../libs/utils/requests';

import CharacterChip from '../CharacterChip';

import CharacterImage from '../CharacterImage';
import css from './PlayerInvitor.module.css';
/**
 * The value attributed to a specific suggestion is the username of the
 * suggestion object
 * @param suggestion - username of this suggestion
 * @returns {*}
 */
function getSuggestionValue(suggestion) {
  return suggestion.name;
}

/**
 * Render a suggestion in the drop-down of Autosuggest
 * This will render a user's avatar alongside their username
 * @param suggestion
 * @param query
 * @returns {XML} React component to render
 */
function renderSuggestion(suggestion, { query }) {
  let suggestionText = suggestion.name;
  const stringSplit = suggestionText.split(query);
  const suggestionSrc = suggestion.avatar;
  const length = stringSplit.length;
  return (
    <span className={css.suggestionContent}>
      <CharacterImage src={suggestionSrc} />
      <span className={css.name}>
        {stringSplit.map((str, i) => (
          <span key={i}>
            {str}
            {i < length - 1 ? <span className={css.query}>{query}</span> : null}
          </span>
        ))}
      </span>
    </span>
  );
}

//TODO: should the sections actually not exist, and groups be shown after the name of a user so they are unified? i.e.
// no user duplication
/**
 * Render a section title for Autocomplete section
 * @param section
 * @returns {XML} React component to render
 */
function renderSectionTitle(section) {
  return <strong>{section.title}</strong>;
}

/**
 * Get section suggestions for Autocomplete section
 * @param section
 * @returns {XML} React component to render
 */
function getSectionSuggestions(section) {
  return section.users;
}

/**
 * Given an array of users, return those that match the given input
 * @param textInput
 * @param sections
 * @returns {*}
 */
function getMatchingUsers(textInput, sections) {
  const inputValue = textInput.trim().toLowerCase();

  if (inputValue.length === 0) {
    return [];
  }

  return sections
    .map(section => ({
      title: section.title,
      users: section.users.filter(user =>
        user.name.toLowerCase().includes(inputValue)
      )
    }))
    .filter(section => section.users.length > 0);
  // return users.filter(user => user.name.toLowerCase().slice(0, inputLength) === inputValue)
}

const renderInputComponent = inputProps => (
  <div className={css.input}>
    <UserIcon className={css.inputIcon} />
    <input {...inputProps} />
  </div>
);

export default class PlayerInvitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingSuggestions: false,
      suggestions: [],
      inputFieldValue: '',
      error: '',
      noSuggestions: false
    };
  }

  //TODO: Are we ok to do all this from the react component
  // (rather than controlled in redux?)
  // probably yes. Maybe. Maybe not..
  /**
   * The suggestions to display based upon the user's input.
   * This will be a list of usernames that begin with the letters
   * inputted by the player
   * @param value
   * @returns {Array}
   */
  loadSuggestions = ({ value }) => {
    this.setState({
      isLoadingSuggestions: true
    });

    const fetchOptions = {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const destination = this.context.api.connections;
    const query = { svg: true };
    //fetch a list of users
    sendRequest({
      destination,
      options: fetchOptions,
      query,
      success: response => {
        const users = response.payload.connections;
        const suggestions = getMatchingUsers(value, users);
        const noSuggestions = value.trim() !== '' && suggestions.length === 0;
        this.setState({
          isLoadingSuggestions: false,
          noSuggestions,
          suggestions
        });
      },
      error: err => {
        console.error('error retrieving usernames', err);
        this.setState({
          isLoadingSuggestions: false,
          error: `There was a problem loading usernames :(`
        });
      }
    });
  };

  clearSuggestions = () => {
    this.setState({
      isLoadingSuggestions: false,
      noSuggestions: false,
      suggestions: []
    });
  };

  renderCharacterChip = (player, key) => {
    const { avatar, label } = player;
    const { handleRemovePlayerClick } = this.props;

    return (
      <CharacterChip
        key={key}
        imgUrl={avatar}
        onDelete={() => {
          this.setState({
            error: ''
          });
          return handleRemovePlayerClick(label);
        }}
        label={label}
      />
    );
  };

  handleAddPlayerClick = (event, selected) => {
    const { suggestion, suggestionValue } = selected;
    const { stagedPlayers, maxInvites, handleAddPlayerClick } = this.props;

    if (!suggestionValue) {
      this.setState({
        error: 'Please enter a player name'
      });
      return;
    }
    if (maxInvites && stagedPlayers.length >= maxInvites) {
      this.setState({
        error: `You can't invite more than ${maxInvites} player${
          maxInvites > 1 ? 's' : ''
        }`
      });
      return;
    }
    //TODO: check against players in the room already (like this but not staged)
    if (
      stagedPlayers.filter(player => player.label === suggestionValue).length >
      0
    ) {
      this.setState({
        error: `You've already invited ${suggestionValue}`
      });
      return;
    }
    const player = {
      label: suggestionValue,
      avatar: suggestion.avatar
    };

    this.setState({
      inputFieldValue: '',
      error: ''
    });

    handleAddPlayerClick(player);
  };

  //controlling name input
  inputFieldOnChange = (event, { newValue }) => {
    this.setState({
      inputFieldValue: newValue,
      error: ''
    });
  };

  render() {
    const { stagedPlayers, message, style } = this.props;
    const {
      inputFieldValue,
      error,
      suggestions,
      isLoadingSuggestions,
      noSuggestions
    } = this.state;

    const inputProps = {
      placeholder: 'Type a username',
      value: inputFieldValue,
      onChange: this.inputFieldOnChange,
      type: 'search'
    };

    const progressStyle = {
      visibility: isLoadingSuggestions ? 'visible' : 'hidden'
    };

    return (
      <div style={style}>
        <p className={css.message}>{message}</p>
        <div className={css.suggestionContainer}>
          <Autosuggest
            multiSection={true}
            renderSectionTitle={renderSectionTitle}
            getSectionSuggestions={getSectionSuggestions}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.loadSuggestions}
            onSuggestionsClearRequested={this.clearSuggestions}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            highlightFirstSuggestion={true}
            onSuggestionSelected={this.handleAddPlayerClick}
            renderInputComponent={renderInputComponent}
            focusInputOnSuggestionClick={false}
            alwaysRenderSuggestions={false}
          />
          <CircularProgress size={0.5} style={progressStyle} />
        </div>
        <div className={css.stagedPlayersWrapper}>
          {stagedPlayers.map(
            (player, key) => this.renderCharacterChip(player, key),
            this
          )}
        </div>
        {error ? <p className={css.error}>{error}</p> : null}
        {noSuggestions && <div>No suggestions</div>}
      </div>
    );
  }
}

PlayerInvitor.contextTypes = {
  api: PropTypes.object
};
