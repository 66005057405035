import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Divider from '@material-ui/core/Divider';

import Information from '../Information';
import AreaHeader from '../../global-components/AreaHeader';

import css from './InformationArea.module.css';

const InformationArea = ({ taskTitle, taskInformation, playerInformation }) => {
  const renderInformation = (taskInformation, playerInformation) => {
    return !playerInformation || playerInformation.isEmpty() ? (
      <div>
        <Information information={taskInformation} />
      </div>
    ) : (
      <div>
        <Information information={taskInformation} />
        <Divider />
        <Information information={playerInformation} />
      </div>
    );
  };

  //need to get task.title
  return (
    <div className={css.fillHeight}>
      <AreaHeader heading={<h2>{taskTitle}</h2>} />
      <div className={css.informationAreaSection}>
        {renderInformation(taskInformation, playerInformation)}
      </div>
      <div className={cn(css.areaHeading, css.informationAreaFooter)} />
    </div>
  );
};
InformationArea.propTypes = {
  taskTitle: PropTypes.string.isRequired,
  taskInformation: PropTypes.shape({
    description: PropTypes.string,
    instructions: PropTypes.string
  }),
  playerInformation: PropTypes.shape({
    description: PropTypes.string,
    instructions: PropTypes.string
  })
};
InformationArea.defaultProps = {
  taskTitle: 'Loading...'
};

export default InformationArea;
