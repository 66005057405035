import React from "react";
import { connect } from "react-redux";

import { actions } from "@school-of-code/soc-redux";

import RegisterDialog from "../../../components/global-components/RegisterDialog";

const { modals: ModalsActions, game: GameActions, ui: UIActions } = actions;

//TODO: lets write some middleware that checks if logged in for any action for modals, and if not shows signup form
const SignUp = ({ onSubmit, onClose }) => (
  <RegisterDialog
    open={true}
    handleSubmitClick={onSubmit}
    handleDismissClick={onClose}
  />
);

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: registerInfo => {
      dispatch(GameActions.signupAndSave(registerInfo));
      dispatch(ModalsActions.hideModal());
      dispatch(UIActions.enableSaveButton());
    },
    onClose: () => {
      dispatch(ModalsActions.hideModal());
      dispatch(UIActions.enableSaveButton());
    },
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);
