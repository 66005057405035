import { createMuiTheme } from "@material-ui/core/styles";
import "../index.css";
import { getColor } from "../utils";

const socMuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: getColor("--mainBlue")
    },
    secondary: {
      main: getColor("--mainGreen")
    },
    tertiary: {
      main: getColor("--darkWhite")
    },
    background: {
      main: getColor("--darkBlue")
    }
  }
});

export default socMuiTheme;
