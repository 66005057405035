import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import css from '../ChatWindow.module.css';

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ''
    };
  }

  //TODO: Autobinding this with ES7 http://www.ian-thomas.net/autobinding-react-and-es6-classes/
  handleTextChange = e => {
    this.setState({ text: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const text = this.state.text.trim();

    if (!text) {
      return;
    }
    if (this.props.onMessageSubmit) {
      this.props.onMessageSubmit(text);
    }
    this.setState({ text: '' });
  };

  render() {
    const { defaultPlaceholder } = this.props;
    const { text } = this.state;

    return (
      <form
        onSubmit={this.handleSubmit}
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          justifyContent: 'space-around'
        }}
      >
        <TextField
          className={css.textField}
          type="text"
          placeholder={defaultPlaceholder || 'Type here...'}
          value={text}
          onChange={this.handleTextChange}
        />
        <Button variant="contained" type="submit" color="primary">
          Send
        </Button>
      </form>
    );
  }
}
