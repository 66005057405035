import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import css from './ChooseACharacter.module.css';

const ChooseACharacter = ({ url, name, flappyUrl, onSelect, selected }) => (
  <div
    className={cn(css.container, { [css.selected]: selected })}
    onClick={onSelect}
  >
    <div className={css.imgSection}>
      <img
        alt={`${name} character`}
        src={url}
        className={cn(css.character, {
          [css.characterSelected]: selected
        })}
      />
    </div>
    <div className={css.actionSection}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={flappyUrl}
        className={cn(css.goPlay, { [css.popIn]: selected })}
      >
        Go play!
      </a>
      <span className={css.name}>{name}</span>
    </div>
  </div>
);

ChooseACharacter.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  flappyUrl: PropTypes.string,
  onSelect: PropTypes.func,
  selected: PropTypes.bool
};

export default ChooseACharacter;
