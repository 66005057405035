import React from 'react';
import classnames from 'classnames';

import PlayerContainer from '../../../containers/PlayerContainer';

import { constants } from '@school-of-code/soc-redux';
import css from './PlayersArea.module.css';

const {
  Misc: { EMPTY_PLAYER_SLOT }
} = constants;

function PlayersArea({ playerNames, playerId, connectedStates }) {
  const singlePlayer = connectedStates.length === 1;
  // TODO: don't use empty player slot here
  return (
    <div className={classnames(css.playerArea, css.fillHeight)}>
      {playerNames.map((player, index) => {
        //  && connectedStates[index]
        return (
          player !== EMPTY_PLAYER_SLOT && (
            <div
              className={classnames({
                [css.playerWindow]: !singlePlayer,
                [css.onePlayerWindow]: singlePlayer
              })}
              key={index}
            >
              <PlayerContainer
                mine={index === playerId}
                playerName={player}
                playerId={index}
                singlePlayerView={singlePlayer}
              />
            </div>
          )
        );
      })}
    </div>
  );
}

PlayersArea.defaultProps = {
  size: 2,
  playerNames: [''],
  playerId: 0
};

export default PlayersArea;
