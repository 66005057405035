import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

import TextInputDialog from '../../global-components/TextInputDialog';
import FileChip from '../../global-components/FileChip';

import css from '../Modal.module.css';
import { getColor } from '../../../utils';
//TODO: need to handle the error and waiting cases, move to a more classic promise chain for dispatching the actions and handling results

const renderFiles = ({ error, options, type, onChange, chosen, onSelect }) => {
  if (error) {
    return <p className={css.error}>{error}</p>;
  }

  if (!options) {
    return (
      <div className={css.container}>
        <p>Loading...</p>
        <LinearProgress color="primary" className={css.refresh} />
      </div>
    );
  }
  return options.map((label, key) => {
    const colors =
      chosen === label
        ? {
            primary: getColor('--mainBlue'),
            background: getColor('--lightWhite')
          }
        : {
            primary: getColor('--grey'),
            background: getColor('--black')
          };
    return (
      <FileChip
        key={key}
        type={type}
        label={label}
        primaryColor={colors.primary}
        secondaryColor={colors.background}
        onDelete={null}
        onClick={() => onSelect(label)}
      />
    );
  });
};

const onSubmitOverride = (name, onSuccess, onError) => {
  let nameCheck = name.trim();
  if (!nameCheck) {
    return onError('Please enter a name');
  }
  return onSuccess(nameCheck);
};

const FileChooserModal = ({
  onClose,
  onSubmit,
  onChange,
  onError,
  options,
  user,
  chosen,
  filter,
  error,
  inputError,
  onSelect,
  type,
  modalType
}) => (
  <TextInputDialog
    open={true}
    onSubmit={text => onSubmitOverride(text, onSubmit, onError)}
    onRequestClose={onClose}
    scrollable={true}
    onTextChange={onChange}
    name={filter}
    chosen={chosen}
    inputError={inputError}
    type="file"
    modalType={modalType}
  >
    {renderFiles({ error, options, type, onChange, chosen, onSelect })}
  </TextInputDialog>
);

export default FileChooserModal;
