import React, { Component } from 'react';
import { ChromePicker } from 'react-color';
import Button from '@material-ui/core/Button';
import CopyToClipboard from 'react-copy-to-clipboard';

const styles = {
  colorContainer: {
    padding: '5px'
    // boxShadow: 'rgba(0, 0, 0, 0.0980392) 0px 0px 0px 1px',
    // borderRadius: '1px'
  },
  color: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    border: '1px solid rgba(0, 0, 0, 0.2)'
  },
  container: {
    position: 'relative',
    padding: '5px',
    margin: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '225px',
    cursor: 'pointer'
  },
  popover: {
    position: 'absolute',
    zIndex: 2
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  },
  icon: {
    small: {
      width: 20,
      height: 20,
      padding: 0
    },
    smallIcon: {
      width: 20,
      height: 20
    }
  }
};

const anchorToStyle = anchor => {
  if (!anchor) return {};
  const points = anchor.split('-');
  return points
    .map(point => {
      switch (point) {
        case 'top':
          return { bottom: '100%' };
        case 'bottom':
          return { top: '100%' };
        case 'left':
          return { left: '0%' };
        case 'right':
          return { right: '0%' };
        case 'center':
        default:
          return {};
      }
    })
    .reduce((styles, rule) => ({ ...styles, ...rule }), {});
};

class ColorPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: true,
      color: {
        r: '241',
        g: '112',
        b: '19',
        a: '1'
      },
      copied: false
    };
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = color => {
    this.setState({ color: color.rgb, copied: false });
  };

  handleChangeComplete = color => {
    this.setState({ color: color.rgb, copied: false });
  };

  onCopy = () => {
    this.setState({ copied: true });
  };

  render() {
    const { anchor, style } = this.props;
    const { displayColorPicker, color, copied } = this.state;
    const rgbaText = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

    return (
      <div style={{ ...styles.container, ...style }}>
        <CopyToClipboard text={rgbaText} onCopy={this.onCopy}>
          {copied ? (
            <Button varirant="text" color="secondary">
              Copied!
            </Button>
          ) : (
            <Button variant="contained" color="primary">
              Copy Me
            </Button>
          )}
        </CopyToClipboard>
        <div style={styles.colorContainer}>
          <div style={{ ...styles.color, background: rgbaText }} />
        </div>
        {displayColorPicker ? (
          <div style={{ ...styles.popover, ...anchorToStyle(anchor) }}>
            <div style={styles.cover} onClick={this.handleClose} />
            <ChromePicker
              color={color}
              onChange={this.handleChange}
              onChangeComplete={this.handleChangeComplete}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPicker;
