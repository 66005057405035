import setChooserModal from "../BaseChooserModal";

import { actions } from "@school-of-code/soc-redux";
const { modals: ModalsActions, game: GameActions } = actions;

export default setChooserModal(
  (chosen, dispatch, { thisPlayerId, challenge }) => {
    dispatch(GameActions.loadContent(thisPlayerId, chosen, challenge));
    dispatch(ModalsActions.hideModal());
  }
);
