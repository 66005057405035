import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import UpdateIcon from '@material-ui/icons/Update';
import PlusIcon from '@material-ui/icons/AddCircle';
import FriendsIcon from '@material-ui/icons/Group';

import AddRoomDialog from '../AddRoomDialog';
import RoomTable from '../RoomTable';
import FriendsModal from '../../modals/FriendsModal';
import { sendRequest } from '../../../libs/utils/requests';
import css from './TableContainer.module.css';

const permittedRoles = ['admin', 'superAdmin'];

//rendering functions
const renderAddRoomButton = (userRole, onClick) => {
  //TODO: secure this properly somehow
  if (permittedRoles.includes(userRole)) {
    return (
      <Button variant="contained" onClick={onClick} className={css.button}>
        <PlusIcon className={css.icon} />
      </Button>
    );
  }
  return null;
};

class RoomTableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rooms: [],
      addRoomDialogOpen: false,
      friendsDialogOpen: false
    };
  }

  componentDidMount() {
    this.fetchRooms();
  }

  //TODO: see if we can do shouldReRender only if room names have changed

  //methods
  fetchRooms = () => {
    const fetchOptions = {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const destination = this.context.api.rooms;
    sendRequest({
      destination,
      options: fetchOptions,
      success: response => {
        this.setState({
          rooms: response.payload.rooms
        });
      },
      error: err => {
        console.error('error retrieving rooms', err);
      }
    });
  };

  /**
   * Handle user clicking the add room button
   * Show the add room dialog
   */
  addRoomClickHandler = () => {
    this.setState({
      addRoomDialogOpen: true
    });
  };

  friendsClickHandler = () => {
    this.setState({
      friendsDialogOpen: true
    });
  };
  friendsDismissHandler = () => {
    this.setState({
      friendsDialogOpen: false
    });
  };

  /**
   * Handle the dissmissal of the add room dialog
   * sets open = false
   */
  addRoomDialogDismissHandler = () => {
    this.setState({
      addRoomDialogOpen: false
    });
  };

  /**
   * Handle the submission of the add room dialog
   * Create a room with the given players and the specified challenge
   */
  addRoomDialogSubmitHandler = () => {
    this.setState({
      addRoomDialogOpen: false
    });
    setTimeout(this.fetchRooms, 1000);
  };

  render() {
    const { userRole } = this.props;
    const { rooms, addRoomDialogOpen, friendsDialogOpen } = this.state;
    return (
      <div>
        <div className={css.header}>
          <div className={css.title}>Your Rooms</div>
          <div>
            <Button
              variant="contained"
              onClick={this.fetchRooms}
              className={css.button}
            >
              <UpdateIcon className={css.icon} />
            </Button>
            {renderAddRoomButton(userRole, this.addRoomClickHandler)}
            <Button
              variant="contained"
              onClick={this.friendsClickHandler}
              className={css.button}
            >
              <FriendsIcon className={css.icon} />
            </Button>
          </div>
        </div>
        <RoomTable tableData={rooms} />
        <AddRoomDialog
          open={addRoomDialogOpen}
          handleDismissClick={this.addRoomDialogDismissHandler}
          handleSubmitClick={this.addRoomDialogSubmitHandler}
        />
        <FriendsModal
          open={friendsDialogOpen}
          handleDismissClick={this.friendsDismissHandler}
          handleOpen={this.friendsClickHandler}
        />
      </div>
    );
  }
}

export default RoomTableContainer;

RoomTableContainer.contextTypes = {
  routes: PropTypes.object,
  api: PropTypes.object
};
