import React from 'react';

import classNames from 'classnames';

import { constants } from '@school-of-code/soc-redux';
import TickIcon from '../../svg-icons/tick';
import CrossIcon from '../../svg-icons/cross';
import css from './ConsoleRender.module.css';

const { PlayerStatuses } = constants;

function handleConsoleStatus(status) {
  switch (status) {
    case PlayerStatuses.PASSED:
      return (
        <div className={classNames(css.baseBar, css.passed)}>
          <TickIcon className={css.barIcon} />
          <h4 className={css.statusText}>All tests passed</h4>
        </div>
      );
    //USE THIS FOR CONSOLE ERROR CHECKING.
    //Console error checking should come before test checking because console errors have a higher proioruty then
    // tests failing.
    case PlayerStatuses.FAILED:
      return (
        <div className={classNames(css.baseBar, css.failed)}>
          <CrossIcon className={css.barIcon} />
          <h4 className={css.statusText}>Error</h4>
        </div>
      );
    default:
      return <div className={classNames(css.baseBar, css.coding)} />;
  }
}

const renderConsole = WrappedComponent => {
  return function({ codeResult, playerStatus }) {
    return (
      <WrappedComponent>
        <div
          className={classNames(
            css.fillHeight,
            css.previewWindowConsole,
            css.console
          )}
        >
          {handleConsoleStatus(playerStatus)}
          <div className={css.output}>
            {codeResult.err && (
              <pre className={css.failedText}>{codeResult.err}</pre>
            )}
            {codeResult.stdout && (
              <pre className={css.codeOutput}>{codeResult.stdout}</pre>
            )}
          </div>
        </div>
      </WrappedComponent>
    );
  };
};

export default renderConsole;
