import setChooserModal from '../BaseChooserModal';

import { actions } from '@school-of-code/soc-redux';

const { modals: ModalsActions, game: GameActions } = actions;

export default setChooserModal(
  (chosen, dispatch, { thisPlayerId, challenge }) => {
    const token = localStorage.getItem('soc-token');
    dispatch(GameActions.saveContent(thisPlayerId, chosen, challenge, token));
    dispatch(ModalsActions.hideModal());
  }
);
