import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import React from 'react';
import PropTypes from 'prop-types';
import { socBlue1, socWhite1 } from '../../../style/soc-colors';
import { getCharacter } from '../../../utils';
import css from './chip.module.css';
//TODO: put in module

const CharacterChip = (
  {
    primaryColor = socBlue1,
    secondaryColor = socWhite1,
    imgUrl,
    label,
    onClick,
    onDelete,
    user,
    character,
    avatar,
    index
  },
  context
) => {
  const url =
    imgUrl ||
    (character &&
      getCharacter({ path: context.usersContentPath, user, character })) ||
    context.defaultCharacterPngUrl;
  return (
    <Chip
      onClick={() => onClick && onClick(url, index)}
      onDelete={onDelete}
      style={{
        backgroundColor: primaryColor,
        color: secondaryColor
      }}
      className={css.chip}
      avatar={
        <Avatar
          alt={`${label} avatar`}
          src={url}
          style={{ color: secondaryColor }}
        />
      }
      label={label}
    />
  );
};
CharacterChip.propTypes = {
  imgUrl: PropTypes.string,
  label: PropTypes.string,
  onDelete: PropTypes.func
};
CharacterChip.defaultProps = {
  title: '',
  label: '',
  onDelete: {}
};
CharacterChip.contextTypes = {
  defaultCharacterPngUrl: PropTypes.string,
  usersContentPath: PropTypes.string
};

export default CharacterChip;
