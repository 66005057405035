import React from 'react';

import ModalRoot from '../components/modals/ModalRoot';

//TODO: no need for higher order components
const addModals = WrappedComponent => props => (
  <>
    <WrappedComponent {...props} />
    <ModalRoot />
  </>
);

export default addModals;
