import { Component } from "react";
import PropTypes from "prop-types";

class SoCContextProvider extends Component {
  getChildContext() {
    const { aws, routes, api } = this.props;
    return {
      ...aws,
      routes,
      api
    };
  }

  render() {
    return this.props.children;
  }
}

SoCContextProvider.propTypes = {
  aws: PropTypes.object,
  routes: PropTypes.object,
  api: PropTypes.object
};
SoCContextProvider.defaultProps = {
  aws: {},
  routes: {},
  api: {}
};
SoCContextProvider.childContextTypes = {
  defaultCharacterPngUrl: PropTypes.string,
  defaultCharacterSvgUrl: PropTypes.string,
  usersContentPath: PropTypes.string,
  getUserCharacterSvgUrl: PropTypes.func,
  routes: PropTypes.object,
  api: PropTypes.object
};

export default SoCContextProvider;
