import React from 'react';
import classNames from 'classnames';

import css from './PreviewWindowBase.module.css';

function PreviewWindowBase({ children }) {
  return (
    <div className={css.container}>
      <div className={css.row}>
        <div className={classNames(css.column, css.left)}>
          <span className={css.dot} />
          <span className={css.dot} />
          <span className={css.dot} />
        </div>
        <div className={classNames(css.column, css.middle)}>
          <div className={css.urlField}>https://www.schoolofcode.io</div>
        </div>
        <div className={classNames(css.column, css.right)}>
          <div className={css.menu}>
            <span className={css.bar} />
            <span className={css.bar} />
            <span className={css.bar} />
          </div>
        </div>
      </div>
      <div className={css.content}>{children}</div>
    </div>
  );
}

export default PreviewWindowBase;
