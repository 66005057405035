import React from 'react';

import ColorPicker from '../ColorPicker';
import Drawer from '@material-ui/core/Drawer';

import css from './Toolbelt.module.css';

const Toolbelt = ({ open, onRequestChange }) => (
  <Drawer open={open} onClose={onRequestChange}>
    <ColorPicker className={css.colorPicker} anchor="bottom-right" />
  </Drawer>
);

export default Toolbelt;
