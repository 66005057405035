import React from "react";
import PropTypes from "prop-types";
import { constants } from "@school-of-code/soc-redux";

import PlayerName from "../player-info/PlayerName";
import PlayerLevelStar from "../player-info/PlayerLevelStar";

const { CssConstants } = constants;

const ProfileInformationArea = ({
  username,
  width,
  height,
  backgroundColor,
  innerAlign,
  padding,
  marginTop,
  stats = {}
}) => {
  const { codeChanged = 0, addMessage = 0, level = 1 } = stats;

  var areaStyle = {
    width,
    height,
    backgroundColor,
    margin: "auto",
    textAlign: "center",
    color: "white",
    padding,
    marginTop
  };

  const statsTableStyle = {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "left"
  };

  return (
    <div className="profile-information-area" style={areaStyle}>
      <PlayerLevelStar level={level} innerAlign={innerAlign} />
      <PlayerName name={username} color="#303030" />
      <div className="profile-information-stats">
        <h3>Stats overview</h3>
        <table style={statsTableStyle}>
          <tbody>
            <tr>
              <td>Code Changed Actions:</td>
              <td>{codeChanged}</td>
            </tr>
            <tr>
              <td>Messages Sent:</td>
              <td>{addMessage}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

ProfileInformationArea.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  align: PropTypes.string.isRequired,
  marginTop: PropTypes.string
};

ProfileInformationArea.defaultProps = {
  width: "80%",
  height: "auto",
  marginTop: "0px",
  align: CssConstants.CENTER
};

export default ProfileInformationArea;
