const nodeEnv = process.env.NODE_ENV;

// Set environment variables
const port = process.env.PORT || '3000';
const host = process.env.HOST || `http://localhost:${port}`;
const domain = process.env.REACT_APP_DOMAIN || 'localhost'; // 'schoolofcode.io';

// api
const apiUrl = process.env.REACT_APP_SOC_API_URL || 'http://localhost:8080';
const apiGameUrl =
  process.env.REACT_APP_SOC_GAME_SERVER_URL || 'http://localhost:3001';

let gAnalyticsEnabled = false;
if (typeof process.env.REACT_APP_G_ANALYTICS_ENABLED !== 'undefined') {
  gAnalyticsEnabled =
    process.env.REACT_APP_G_ANALYTICS_ENABLED.toString().toLowerCase() ===
    'true';
}

// AWS
const awsRegion = process.env.REACT_APP_AWS_REGION || 'eu-west-1';
const contentBucket =
  process.env.REACT_APP_AWS_S3_BUCKET_CONTENT || 'soc-platform-content-dev';
const s3Url = `https://s3.${awsRegion}.amazonaws.com`;

let tawkEnabled = false;
if (typeof process.env.REACT_APP_TAWK_ENABLED !== 'undefined') {
  tawkEnabled =
    process.env.REACT_APP_TAWK_ENABLED.toString().toLowerCase() === 'true';
}
const tawkPropertyUrl = process.env.REACT_APP_TAWK_PROPERTY_URL;

// SoC Services
const socUploadContentUrl =
  process.env.REACT_APP_SOC_UPLOAD_CONTENT_URL || 'http://localhost:3080';

const config = {
  nodeEnv,
  port,
  host,
  domain,
  secret: process.env.REACT_APP_SOC_SECRET || '7EJ+lsMk4(T:4N;LX7Ohgj3&69M9?S',
  routes: {
    health: '/health',
    login: '/login',
    home: '/',
    passwordReset: '/password-reset',
    passwordResetEntry: '/password-reset-entry',
    logout: '/logout',
    register: '/register',
    leaderboard: '/leaderboard',
    game: '/game',
    groups: '/groups',
    gameAvatar: '/game-avatar',
    metrics: '/metrics',
    rooms: '/rooms',
    tasks: '/tasks',
    challenges: '/challenges',
    users: '/users',
    profile: '/profile',
    index: '/',
    courseLibrary: '/course-library',
    floppyCharacter: '/flappy.html',
    guide: '/guide',
    group: '/group',
    connections: '/connections',
    characters: '/characters',
    feedback: '/feedback',
    api: '/api',
    pregame: '/pregame'
  },
  google: {
    analytics: {
      enabled: gAnalyticsEnabled
    }
  },
  aws: {
    region: awsRegion,
    s3: {
      url: s3Url,
      // TODO: change to general user resources/assets rather than svg and images for all users in separate buckets
      contentBucket
    }
  },
  // TODO: import these from environment or existing apps sp they reflect those urls
  // well, this is sort of client service urls.
  serviceUrls: {
    // TODO: currently svg-converter, rename to something else
    uploadContent: {
      url: socUploadContentUrl,
      // TODO: change throughout app away from character specific
      saveContentUrl: `${socUploadContentUrl}/content`,
      loadContentUrl: `${socUploadContentUrl}/content`,
      loadFileOptionsUrl: `${socUploadContentUrl}/fs`
    },
    game: {
      url: apiGameUrl
    },
    // soc-api
    // TODO: generate routes instead of hardcoding in each apiUrl
    api: {
      url: apiUrl,
      actions: `${apiUrl}/actions`,
      challenges: `${apiUrl}/challenges`,
      characters: `${apiUrl}/characters`,
      connections: `${apiUrl}/connections`,
      feedback: `${apiUrl}/feedback`,
      groups: `${apiUrl}/groups`,
      guide: `${apiUrl}/guide`,
      health: `${apiUrl}/health`,
      login: `${apiUrl}/login`,
      logout: `${apiUrl}/logout`,
      passwordReset: `${apiUrl}/password-reset`,
      passwordResetEntry: `${apiUrl}/password-reset-entry`,
      rooms: `${apiUrl}/rooms`,
      tasks: `${apiUrl}/tasks`,
      users: `${apiUrl}/users`
    }
  },
  tawk: {
    enabled: tawkEnabled,
    propertyUrl: tawkPropertyUrl
  }
};

export default config;
