//TODO: this whole file is terrible - when you have time find a better way to make react forms (use existing library)
//TODO: equally a HOC pattern might work better

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { constants } from '@school-of-code/soc-redux';

import { getValidationText } from '../../libs/client/validator';
import * as utils from '../../libs/utils/requests';
import css from './Form.module.css';

const { AuthFormTypes: formTypes } = constants;

const requests = {
  [formTypes.REGISTER]: utils.registerRequest,
  [formTypes.PASSWORD_RESET]: utils.passwordResetRequest,
  [formTypes.PASSWORD_RESET_ENTRY]: utils.passwordResetEntryRequest
};

const uses = {
  username: [formTypes.REGISTER],
  password: [formTypes.REGISTER, formTypes.PASSWORD_RESET_ENTRY],
  email: [formTypes.REGISTER, formTypes.PASSWORD_RESET]
};

class RootAuthForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      username: '',
      password: '',
      email: '',
      errorText: '',
      usernameErrorText: '',
      passwordErrorText: '',
      emailErrorText: ''
    };
  }

  handleUsernameChange = event => {
    const { type } = this.props;
    if (uses.username.includes(type)) {
      this.setState({
        username: event.target.value,
        usernameErrorText: getValidationText(event.target.value, 'username')
      });
    }
  };

  handlePasswordChange = event => {
    const { type } = this.props;
    console.log('handleChange', type, uses.password);
    if (uses.password.includes(type)) {
      return this.setState({
        password: event.target.value,
        passwordErrorText: getValidationText(event.target.value, 'password')
      });
    }
  };

  handleEmailChange = event => {
    const { type } = this.props;
    if (uses.email.includes(type)) {
      this.setState({
        email: event.target.value,
        emailErrorText: getValidationText(event.target.value, 'email')
      });
    }
  };

  submitForm = e => {
    e.preventDefault();
    const {
      // destination,
      success,
      failure,
      type
    } = this.props;
    const { username, password, email } = this.state;
    if (!this.checkFormReady()) {
      return;
    }
    const request = requests[type];
    return request(
      {
        username,
        password,
        email,
        routes: this.context.routes,
        api: this.context.api
      },
      success,
      error => {
        this.setState({
          errorText: error.message
        });
        failure && failure(error);
      }
    );
  };

  checkFormReady = () => {
    const { type } = this.props;
    const {
      username,
      email,
      password,
      emailErrorText,
      usernameErrorText,
      passwordErrorText
    } = this.state;
    let valueArray = [];
    uses.username.includes(type) && valueArray.push(username);
    uses.password.includes(type) && valueArray.push(password);
    uses.email.includes(type) && valueArray.push(email);
    if (valueArray.filter(c => c === '').length > 0) {
      this.setState({
        errorText: 'Fields cannot be left empty :('
      });
      return false;
    }
    let textArray = [];
    uses.username.includes(type) && textArray.push(usernameErrorText);
    uses.password.includes(type) && textArray.push(passwordErrorText);
    uses.email.includes(type) && textArray.push(emailErrorText);
    if (textArray.filter(t => t !== '').length > 0) {
      this.setState({
        errorText: 'Fix the errors in the form first :)'
      });
      return false;
    }

    this.setState({
      errorText: ''
    });
    return true;
  };

  render() {
    const { type, metaError } = this.props;
    const {
      username,
      password,
      email,
      errorText,
      usernameErrorText,
      passwordErrorText,
      emailErrorText
    } = this.state;
    return (
      <form onSubmit={this.submitForm}>
        {uses.username.includes(type) && (
          <TextField
            id="username"
            value={username}
            onChange={this.handleUsernameChange}
            placeholder="e.g. coolcoder99"
            label="Username"
            fullWidth
            helperText={usernameErrorText}
            error={Boolean(usernameErrorText)}
          />
        )}
        {uses.password.includes(type) && (
          <TextField
            id="password"
            value={password}
            onChange={this.handlePasswordChange}
            placeholder="Type in your password here"
            label="Password"
            type="password"
            fullWidth
            helperText={passwordErrorText}
            error={Boolean(passwordErrorText)}
          />
        )}
        {uses.email.includes(type) && (
          <TextField
            id="email"
            value={email}
            onChange={this.handleEmailChange}
            placeholder="e.g. you@email.com"
            label="Email"
            fullWidth
            helperText={emailErrorText}
            error={Boolean(emailErrorText)}
          />
        )}
        <div className={css.error}>{metaError ? metaError : errorText}</div>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={css.button}
        >
          {type.toUpperCase()}
        </Button>
      </form>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    metaError: state.meta.metaError
  };
};
export default connect(mapStateToProps)(RootAuthForm);

RootAuthForm.contextTypes = {
  routes: PropTypes.object,
  api: PropTypes.object
};
