//Theme Colours

export const socLightGreen = "#23ddae";

export const socBlue1 = "#02A8F3";
export const socWhite1 = "#fff";
export const socGray1 = "#e3e3e3";
export const socOrange1 = "#e48414";
export const socCharcoal1 = "#453E4A";

//////////////////////////////////////////////////
//ROOM COLOURS                                  //
//////////////////////////////////////////////////

export const socPositiveGreen = "#a4c639";
export const socPositiveGreenMuted = "#8AA62F";

export const socRedError = "#DC143C";
export const socGreenPass = "#00CD66";
export const socOrangeError = "#FF8C00";

//status colours
export const socStatusNeutral = "#CCC";
export const socStatusRed = "red";
export const socStatusGreen = socPositiveGreen;
export const socStatusOrange = "orange";

//Preview window
export const socPreviewWindowGrey = "#141414";
export const socPreviewWindowBorderGrey = "#5a5a5a";

//language colours
export const socColorHtml = "#f1672c";
export const socColorCss = "#30a9dc";
export const socColorJavascript = "#f1bf26";

//tab color
export const socDarkGreyTab = "#353535";
