import React from 'react';
import PropTypes from 'prop-types';
import css from './ContentBox.module.css';

const ContentBox = ({
  text,
  width,
  height,
  align,
  display,
  float,
  backgroundColor,
  padding,
  margin,
  marginTop,
  marginBottom,
  boxInfo,
  boxArrows,
  children
}) => {
  var sliderStyle = {
    width,
    height,
    backgroundColor,
    margin: align,
    marginTop: margin,
    marginBottom: margin,
    padding,
    display,
    float
  };

  const hrStyle = {
    marginTop,
    marginBottom,
    display: boxInfo
  };

  const textStyle = {
    display: boxInfo
  };

  return (
    <div className={css.slider} style={sliderStyle}>
      <h3 className={css.text} style={textStyle}>
        {text}
      </h3>
      <hr className={css.rule} style={hrStyle} />
      {children}
    </div>
  );
};

ContentBox.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string,
  align: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
  backgroundColor: PropTypes.string,
  boxInfo: PropTypes.string,
  boxArrows: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string
};

ContentBox.defaultProps = {
  text: 'Box Title',
  width: '100%',
  height: 'auto',
  aling: 'none',
  padding: '20px',

  boxInfo: 'block',
  boxArrows: 'none'
};

export default ContentBox;
