import { connect } from "react-redux";

import { actions, constants, selectors } from "@school-of-code/soc-redux";

import PlayerWindow from "../components/player-window/PlayerWindow";

const { game: GameActions, modals: ModalActions } = actions;
const { ModalTypes, PlayerStatuses } = constants;
const {
  ui: { selectSaveButtonEnabled },
  game: {
    selectCurrentTask,
    selectIsFinalTask,
    selectCombinedPlayerStatus,
    selectPlayerInfoFromPlayerId,
    selectPlayerStateFromPlayerId
  }
} = selectors;

const mapStateToProps = (state, ownProps) => {
  const { mine, playerId } = ownProps;
  const playerInfo = selectPlayerInfoFromPlayerId(state, playerId);
  const playerState = selectPlayerStateFromPlayerId(state, playerId);

  let userInputs = [];
  if (playerState) {
    const languages = (playerInfo && playerInfo.languages) || [];
    const code = playerState.code;

    //TODO: get name from elsewhere
    //TODO: should be a check here for whether read-only task windows or a free project, and act accordingly
    //TODO: this should be the core state, not from languages. Need to hook up properly so that task info is just a seed
    userInputs = languages.map((language, i) => ({
      name: language,
      type: language,
      content: code[i]
    }));
  }

  const combinedPlayerStatus = selectCombinedPlayerStatus(state);
  const { DONE, READY_FOR_NEXT } = PlayerStatuses;
  const readOnly =
    !mine ||
    (combinedPlayerStatus === READY_FOR_NEXT || combinedPlayerStatus === DONE);

  return {
    ...ownProps,
    task: selectCurrentTask(state),
    playerInfo,
    playerState: playerState || {},
    isFinalTask: selectIsFinalTask(state),
    combinedPlayerStatus,
    readOnly,
    userInputs,
    saveButtonEnabled: selectSaveButtonEnabled(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleHintClick: () => {
      dispatch(
        ModalActions.showModal({
          modalType: ModalTypes.HINT,
          meta: {
            premium: true
          }
        })
      );
    },
    handleRunClick: () => {
      dispatch(GameActions.remoteRequestRunCode());
    },
    handleSubmitClick: () => {
      dispatch(GameActions.remoteRequestSubmitCode());
    },
    handleSkipClick: () => {
      dispatch(GameActions.remoteRequestSkipTask());
    },
    handleNextClick: () => {
      dispatch(GameActions.remoteRequestNextTask());
    },
    //TODO: should this be injected from the hoc addSaveCharacterButton? or can we make generic and overrideable for specific tasks
    handleSaveClick: () => {
      //TODO: should be 1 point of contact (loadSaveModal, etc) that handles all this flow
      dispatch(ModalActions.loadFileOptions());
      dispatch(
        ModalActions.showModal({
          modalType: ModalTypes.SAVE_CHARACTER,
          modalProps: { optionsType: "character" },
          meta: {
            premium: true
          }
        })
      );
    },
    handleLoadClick: () => {
      //TODO: dummy, do not use really like this with 'characters' hard coded
      dispatch(ModalActions.loadFileOptions());
      dispatch(
        ModalActions.showModal({
          modalType: ModalTypes.LOAD_CHARACTER,
          modalProps: { optionsType: "character" },
          meta: {
            premium: true
          }
        })
      );
    },
    dispatch: dispatch
  };
};

const mergeProps = (stateProps, dispatchProps) => {
  const { playerId } = stateProps;
  const { dispatch } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    handleCodeChange: (code, codeIndex) => {
      //TODO: Can get playerId on server from socket now
      dispatch(GameActions.codeChanged(playerId, code, codeIndex));
    },
    //TODO: change so only 1 editor on show, and switch the content - this could increase performance in more complex scenarios
    handleEditorTabChange: editorIndex => {
      dispatch(GameActions.setPlayerEditorIndex(playerId, editorIndex));
    }
  };
};

const PlayerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PlayerWindow);

export default PlayerContainer;
