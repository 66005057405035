import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import PlayerInvitor from '../../global-components/PlayerInvitor';

import { sendRequest } from '../../../libs/utils/requests';
import css from './AddGroupDialog.module.css';

export default class AddGroupDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameFieldValue: '',
      stagedPlayers: []
    };
  }

  handleNameFieldChange = event => {
    const { value } = event.target;
    this.setState(() => ({
      nameFieldValue: value
    }));
  };

  submitClickOverride = e => {
    e.preventDefault();
    this.createGroup();
  };

  /**
   * When a user adds a player to the invitor
   * Add this new player to the state
   * TODO: The whole interaction between here and PlayerInvitor can be done better really..
   */
  addPlayerClickOverride = player => {
    const { stagedPlayers } = this.state;
    stagedPlayers.push(player);
    this.setState({
      stagedPlayers
    });
  };

  /**
   * When a user removes a player from the invitor
   * Remove the specified player from the stagedPlayers state
   */
  removeStagedPlayer = key => {
    const { stagedPlayers } = this.state;
    //find where this key is in the array
    const playerToDelete = stagedPlayers.map(player => player.key).indexOf(key);
    //remove that item from the array
    stagedPlayers.splice(playerToDelete, 1);
    this.setState({
      stagedPlayers
    });
  };

  /**
   * Post to api to create a group
   */
  createGroup = () => {
    const { nameFieldValue, stagedPlayers } = this.state;
    const playerNames = stagedPlayers.map(player => player.label);

    const { handleSubmitClick } = this.props;

    const fetchOptions = {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        //TODO: temp measure whilst req.session not working
        members: playerNames,
        groupName: nameFieldValue
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const destination = this.context.api.groups;

    sendRequest({
      destination,
      options: fetchOptions,
      success: res => {
        this.setState({
          stagedPlayers: []
        });
        handleSubmitClick(res.payload.group);
      },
      error: err => {
        console.error('error creating group', err);
        handleSubmitClick(err);
      }
    });
  };

  render() {
    const { open, handleDismissClick } = this.props;

    const { nameFieldValue, stagedPlayers } = this.state;

    return (
      <Dialog open={open} className={css.dialogBody}>
        <DialogTitle>Add Group</DialogTitle>
        <DialogContent>
          <h4>Choose a group name</h4>
          <TextField
            value={nameFieldValue}
            label="Choose a group name"
            placeholder="Class 4B"
            onChange={this.handleNameFieldChange}
          />
          <PlayerInvitor
            stagedPlayers={stagedPlayers}
            handleAddPlayerClick={this.addPlayerClickOverride}
            handleRemovePlayerClick={this.removeStagedPlayer}
            message="Add members to the group by typing a username"
            className={css.playerInvitor}
          />

          <DialogActions>
            <Button onClick={handleDismissClick}>Dismiss</Button>
            <Button
              label="Submit"
              color="primary"
              onClick={this.submitClickOverride}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
}

AddGroupDialog.propTypes = {
  /**
   * Is this dialog open or not?
   */
  open: PropTypes.bool,
  /**
   * function to handle submit
   */
  handleSubmitClick: PropTypes.func,
  /**
   * function to handle dismiss
   */
  handleDismissClick: PropTypes.func
};

AddGroupDialog.defaultProps = {
  open: false
};

AddGroupDialog.contextTypes = {
  routes: PropTypes.object,
  api: PropTypes.object
};
