import React, { Component } from "react";
import PropTypes from "prop-types";

export default class CharacterImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false
    };
  }

  handleError = event => {
    console.log("Error loading character", event);
    this.setState(() => ({
      error: true
    }));
  };

  render() {
    const { src, style } = this.props;
    const { error } = this.state;
    const { defaultCharacterPngUrl } = this.context;
    return (
      <img
        src={error ? defaultCharacterPngUrl : src}
        onError={this.handleError}
        style={style}
        alt="Player Character"
      />
    );
  }
}

CharacterImage.propTypes = {
  src: PropTypes.string,
  style: PropTypes.object
};
CharacterImage.defaultProps = {
  src: "",
  style: {}
};
CharacterImage.contextTypes = {
  defaultCharacterPngUrl: PropTypes.string.isRequired
};
