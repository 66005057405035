import React from "react";
import PropTypes from "prop-types";
import { Link, Route } from "react-router-dom";

import GroupTableContainer from "../GroupTableContainer";

const styles = {
  active: { color: "red" }
};

const GroupsPage = (props, context) => (
  <div>
    <ul>
      <li>
        <Link to={props.match.url} activeClassName={styles.active}>
          Home
        </Link>
      </li>
      <li>
        <Link to={`${props.match.url}/list`} activeClassName={styles.active}>
          Groups
        </Link>
      </li>
    </ul>

    <hr />

    <Route path={props.match.url} exact component={Home} />
    <Route
      path={`${props.match.url}/list`}
      render={({ match }) => <Groups match={match} context={context} />}
    />
  </div>
);

const Home = () => (
  <div>
    <h2>Home</h2>
  </div>
);

const Groups = ({ match, context }) => (
  <div>
    <h2>Groups</h2>
    <GroupTableContainer
      match={match}
      onGroupSelection={group => {
        context.router.history.push(`${match.url}/${group.name}`);
      }}
    />

    <Route
      exact
      path={`${match.url}/:groupId`}
      render={({ match }) =>
        !match.params.groupId ? (
          <h3>Please select a group.</h3>
        ) : (
          <h3>Selected {match.params.groupId}</h3>
        )
      }
    />
  </div>
);

GroupsPage.contextTypes = {
  router: PropTypes.object
};

export default GroupsPage;
