import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Paper from "@material-ui/core/Paper";

const renderList = (list, separator = " ", style = {}) => {
  if (!list || list.length === 0) {
    return "-";
  }
  const length = list.length;
  return list.map((item, index) => {
    const insertSeparator =
      index < length - 1 ? <span>{separator}</span> : null;
    const insertItem = item ? item : "-";
    return (
      <span key={index} className={style}>
        {insertItem}
        {insertSeparator}
      </span>
    );
  });
};

export default ({
  onRowSelection,
  height = "300px",
  table = [],
  buttons = [],
  headers = [],
  footers = []
}) => {
  return (
    <Paper>
      <Table height={height} onRowSelection={onRowSelection}>
        <TableHead>
          {buttons && (
            <TableRow>
              {buttons.map((b, i) => (
                <TableCell key={i} colSpan="1">
                  {b}
                </TableCell>
              ))}
            </TableRow>
          )}
          {headers && (
            <TableRow>
              {headers.map((h, i) => {
                if (h.content) {
                  const { tooltip, content } = h;
                  return (
                    <TableCell key={i} tooltip={tooltip}>
                      {content}
                    </TableCell>
                  );
                }
                return <TableCell key={i}>{h}</TableCell>;
              })}
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {table.data &&
            table.data.map((data, i) => (
              <TableRow key={i}>
                {data.map &&
                  data.map((d, j) => {
                    if (table.render) {
                      return (
                        <TableCell key={j}>{table.render(d, j)}</TableCell>
                      );
                    }
                    if (d.content) {
                      if (Array.isArray(d.content)) {
                        return (
                          <TableCell key={j}>
                            {renderList(d.content, d.delimiter, d.style)}
                          </TableCell>
                        );
                      }
                      return <TableCell key={j}>{d.content}</TableCell>;
                    }
                    if (d.map) {
                      return (
                        <TableCell key={j}>
                          {d.map((item, k) => (
                            <span key={k}>{item}</span>
                          ))}
                        </TableCell>
                      );
                    }
                    return <TableCell key={j}>{d}</TableCell>;
                  })}
              </TableRow>
            ))}
        </TableBody>
        {footers && (
          <TableFooter>
            <TableRow>
              {footers.map((f, i) => {
                if (f.content) {
                  const { tooltip, content } = f;
                  return (
                    <TableCell key={i} tooltip={tooltip}>
                      {content}
                    </TableCell>
                  );
                }
                return <TableCell key={i}>{f}</TableCell>;
              })}
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </Paper>
  );
};
