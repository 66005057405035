import React from 'react';
import PropTypes from 'prop-types';

import ProfilePicture from './profile-picture';

const avatarBox = {
  backgroundColor: '#4a90e2a3',
  color: 'white',
  padding: '2rem',
  width: '100%',
  boxSizing: 'border-box',
  marginLeft: '-1rem'
};

const ProfileHeader = ({ width, height, avatarUrl, avatarEditUrl }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: '1rem'
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%'
        }}
      >
        <ProfilePicture
          imageURL={avatarUrl}
          avatarEditUrl={avatarEditUrl}
          backgroundColour="linear-gradient(
          to right bottom,
          #dd5668,
          #e26454,
          #e17642,
          #d88a32,
          #ca9d29
        )"
        />
        <div style={avatarBox}>
          <p>
            We've already created a room for you in the "Your Rooms" section
            below. Click on a room to select it, and the press "Join" to jump
            into a game.
          </p>
          <br />
          <p>
            Hover over the picture on your left to choose an avatar from your
            collection of characters. If you haven't made any yet, what are you
            waiting for?
          </p>
          <br />
          <p>
            Follow us for the latest updates at
            <br />
            <h2>
              <a
                href="https://twitter.com/theschoolofcode"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'white' }}
              >
                @theSchoolOfCode
              </a>
            </h2>
          </p>
        </div>
      </div>
    </div>
  );
};

ProfileHeader.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string
};

ProfileHeader.defaultProps = {
  width: '100%',
  height: '300px'
};

export default ProfileHeader;
