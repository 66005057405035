import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import ChooseACharacter from '../ChooseACharacter';
import { actions } from '@school-of-code/soc-redux';

const { modals: ModalsActions } = actions;

function CharacterList({
  characters = [],
  username,
  handleLoadClick,
  getUserCharacterSvgUrl,
  defaultCharacterUrl,
  routes
}) {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    handleLoadClick();
  }, [handleLoadClick]);

  return characters.map((character, i) => {
    const url = (
      getUserCharacterSvgUrl({
        user: username,
        character
      }) || defaultCharacterUrl
    ).replace(/ /g, '%20');
    const flappyUrl = `${
      routes.floppyCharacter
    }?characterUrl=${encodeURIComponent(
      getUserCharacterSvgUrl({
        user: username,
        character
      })
    )}`;
    return (
      <ChooseACharacter
        key={i}
        url={url}
        name={character}
        flappyUrl={flappyUrl}
        onSelect={() => setSelected(i)}
        selected={selected === i}
      />
    );
  });
}

const mapStateToProps = (state, ownProps) => {
  return {
    characters: state.modals.modalProps.options,
    username: state.meta.username,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleLoadClick: () => {
      dispatch(ModalsActions.loadCharacters());
    },
    dispatch: dispatch
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CharacterList);
