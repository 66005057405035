import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import ProfileIcon from '@material-ui/icons/Mood';
import HardwareVideogameAsset from '@material-ui/icons/VideogameAsset';
import LogoutIcon from '@material-ui/icons/KeyboardTab';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';

import { actions, selectors } from '@school-of-code/soc-redux';
import css from './navbar.module.css';

const { meta: MetaActions } = actions;
const {
  meta: { selectIsAuthenticated }
} = selectors;

class Navbar extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      open: false
    };
  }

  handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  toggleDrawer = open => () => {
    this.setState(() => ({ open }));
  };

  render() {
    const { open } = this.state;
    const { isAuthenticated, handleLogout, history } = this.props;
    const { routes } = this.context;
    return (
      <AppBar position="static">
        <Toolbar variant="dense">
          <div className={css.content}>
            <Link to={routes.home}>
              <img
                className={css.logo}
                src="/images/soc-logo.png"
                alt="School of Code"
              />
            </Link>
          </div>
          <div>
            {isAuthenticated ? (
              <>
                <Button
                  className={css.button}
                  onClick={this.toggleDrawer(true)}
                >
                  Menu
                </Button>
                <Drawer
                  anchor="right"
                  open={open}
                  onClose={this.toggleDrawer(false)}
                  classes={{ paper: css.drawer }}
                >
                  <AppBar position="static" onClick={this.handleRequestClose}>
                    <Toolbar variant="dense">
                      <Button className={css.button}>Close</Button>
                    </Toolbar>
                  </AppBar>
                  <MenuList className={css.linkList}>
                    <MenuItem
                      className={css.link}
                      onClick={() => {
                        history.push(routes.home);
                        this.handleRequestClose();
                      }}
                    >
                      Home
                      <HomeIcon className={css.icon} />
                    </MenuItem>
                    <MenuItem
                      className={css.link}
                      onClick={() => {
                        history.push(routes.profile);
                        this.handleRequestClose();
                      }}
                    >
                      My Profile
                      <ProfileIcon className={css.icon} />
                    </MenuItem>
                    <MenuItem
                      className={css.link}
                      onClick={() => {
                        history.push(routes.pregame);
                        this.handleRequestClose();
                      }}
                    >
                      Go Play
                      <HardwareVideogameAsset className={css.icon} />
                    </MenuItem>
                    <MenuItem onClick={handleLogout} className={css.link}>
                      Sign Out <LogoutIcon className={css.icon} />
                    </MenuItem>
                  </MenuList>
                </Drawer>
              </>
            ) : (
              <Link to={routes.login}>
                <Button className={css.button}>Login</Button>
              </Link>
            )}
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

Navbar.contextTypes = {
  routes: PropTypes.object,
  api: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: selectIsAuthenticated(state),
    ...ownProps
  };
};
const mapDispatchToProps = dispatch => {
  return {
    handleLogout: () => {
      dispatch(MetaActions.logoutUser());
    },
    dispatch
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Navbar)
);
