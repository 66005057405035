import React, { Component } from "react";
import { connect } from "react-redux";
import { selectors } from "@school-of-code/soc-redux";

const {
  meta: { selectUsername }
} = selectors;

const withMultiplayerMessaging = socket => WrappedComponent => {
  class MultiplayerMessaging extends Component {
    componentDidMount() {
      this.setupSocket();
    }

    setupSocket = () => {
      socket.on("actionToClient", this.props.handleActionToClient.bind(this));
    };

    playerJoin = roomId => {
      socket.emit(
        "player.join",
        this.props.username,
        roomId,
        localStorage.getItem("soc-token")
      );
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          socket={socket}
          onPlayerJoinRoom={this.playerJoin}
        />
      );
    }
  }

  const mapStateToProps = (state, ownProps) => {
    return {
      username: selectUsername(state),
      ...ownProps
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      handleActionToClient: action => {
        dispatch(action);
      },
      dispatch
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(MultiplayerMessaging);
};

export default withMultiplayerMessaging;
