import React from 'react';
import { connect } from 'react-redux';

import { actions, constants, selectors } from '@school-of-code/soc-redux';

import PlayerInviteButton from '../../player-window/PlayerInviteButton';

const { modals: ModalActions } = actions;
const { ModalTypes } = constants;
const {
  game: { selectIsSpaceInRoom }
} = selectors;

const InvitePlayer = ({ handleInvitePlayerClick, allowedToInvite, ...props }) =>
  allowedToInvite && (
    <PlayerInviteButton
      {...props}
      handleInvitePlayerClick={handleInvitePlayerClick}
    />
  );

const mapStateToProps = (state, ownProps) => {
  return {
    allowedToInvite: selectIsSpaceInRoom(state),
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleInvitePlayerClick: () => {
      dispatch(
        ModalActions.showModal({
          modalType: ModalTypes.INVITE_PLAYER,
          meta: {
            premium: true
          }
        })
      );
    },
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitePlayer);
