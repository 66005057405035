import { format } from 'url';

export const sendRequest = ({
  destination,
  options = {},
  success,
  error,
  waiting,
  query
}) => {
  const token = localStorage.getItem('soc-token');
  let headers = {};
  if (options.headers) {
    headers = {
      ...options.headers
    };
  }
  headers = {
    ...headers,
    Authorization: `Bearer ${token}`
  };
  const fetchOptions = {
    ...options,
    headers
  };
  waiting && waiting();
  const sendTo = query ? `${destination}${format({ query })}` : destination;
  return fetch(sendTo, fetchOptions)
    .then(response => {
      if (response.status >= 400) {
        return response.json().then(err => {
          console.log('err', err);
          throw err;
        });
      }
      // TODO: maybe not json here, but let resolve() sort it
      return response.json();
    })
    .then(response => {
      success && success(response);
    })
    .catch(err => {
      console.error('the error', err);
      error && error(err);
    });
};

export const registerRequest = (
  { username, password, email, routes, api },
  success,
  failure
) => {
  const destination = api.users;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username,
      password,
      email
    })
  };
  sendRequest({
    destination,
    options,
    success: response => {
      // TODO: should be soc-token ...? as in soc-redux.meta.loginUser
      localStorage.setItem('soc-token', response.payload.token);
      window.location.replace(routes.home);
    },
    error: error => {
      failure && failure(error);
    }
  });
};

export const passwordResetRequest = (
  { email, routes, api },
  success,
  failure
) => {
  const destination = api.passwordReset;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email
    })
  };
  sendRequest({
    destination,
    options,
    success: () => window.location.replace(routes.passwordResetEntry),
    error: error => failure && failure(error)
  });
};

export const passwordResetEntryRequest = (
  { password, routes, api },
  success,
  failure
) => {
  const destination = api.passwordResetEntry;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      password
    })
  };
  sendRequest({
    destination,
    options,
    success: () => window.location.replace(routes.home),
    error: error => failure && failure(error)
  });
};
