import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { getValidationText } from '../../../libs/client/validator';
import css from '../Form.module.css';

class ForgottenPasswordForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: '',
      errorText: '',
      emailErrorText: ''
    };
  }

  handleEmailChange = event => {
    this.setState({
      email: event.target.value,
      emailErrorText: getValidationText(event.target.value, 'email')
    });
  };

  submitForm = () => {
    const { onSubmit } = this.props;
    const { email } = this.state;
    if (!this.checkFormReady()) {
      return;
    }
    return onSubmit({
      email
    });
  };

  checkFormReady = () => {
    const { email, emailErrorText } = this.state;
    if (!email) {
      this.setState({
        errorText: 'Fields cannot be left empty :('
      });
      return false;
    }
    if (emailErrorText) {
      this.setState({
        errorText: 'Fix the errors in the form first :)'
      });
      return false;
    }
    this.setState({
      errorText: ''
    });
    return true;
  };

  render() {
    const { email, errorText, emailErrorText } = this.state;
    const { metaError, apiMessage } = this.props;
    return (
      <form>
        <TextField
          id="email"
          value={email}
          onChange={this.handleEmailChange}
          placeholder="e.g. me@email.com"
          label="Email"
          fullWidth
          errorText={emailErrorText} // NOTE: find how to do this
        />
        <div className={css.error}>{metaError ? metaError : errorText}</div>
        {apiMessage && <div className={css.error}>{apiMessage}</div>}
        <Button
          variant="contained"
          label="Reset Password"
          color="primary"
          onClick={this.submitForm}
          className={css.button}
        >
          Reset Password
        </Button>
      </form>
    );
  }
}

ForgottenPasswordForm.contextTypes = {
  routes: PropTypes.object,
  api: PropTypes.object
};

export default ForgottenPasswordForm;
