import config from "../../config/config";
import { constants } from "@school-of-code/soc-redux";

const { ChallengeData } = constants;

const { s3 } = config.aws;
const { url: s3Url, contentBucket } = s3;

const DEFAULT_IMAGE_SIZE = 128;
const DEFAULT_TAIL = ".svg";
const defaultCharacterPath = `${s3Url}/${contentBucket}/default/${
  ChallengeData.CHARACTER_BUILDING_CHALLENGE_URL
}`;
const defaultCharacterSvgUrl = `${defaultCharacterPath}/character.svg`;
const defaultCharacterPngUrl = `${defaultCharacterPath}/character_${DEFAULT_IMAGE_SIZE}.png`;
const usersContentPath = `${s3Url}/${contentBucket}/users`;

const getUserCharacterUrl = ({ user, character, tail = DEFAULT_TAIL }) =>
  `${usersContentPath}/${user}/${
    ChallengeData.CHARACTER_BUILDING_CHALLENGE_URL
  }/${character || user}/${character || user}${tail}`;
const getUserCharacterSvgUrl = ({ user, character }) =>
  getUserCharacterUrl({ user, character, tail: `.svg` });

export default {
  defaultCharacterSvgUrl,
  defaultCharacterPngUrl,
  usersContentPath,
  getUserCharacterSvgUrl
};
