import React from 'react';
import PlayerLevel from '../PlayerLevel';

const PlayerLevelStar = ({ level, size, innerAlign }) => {
  return (
    <PlayerLevel
      URL="/images/level_bg.png"
      level={level}
      size={size}
      innerAlign={innerAlign}
      align="center"
    />
  );
};

export default PlayerLevelStar;
