import { constants } from "@school-of-code/soc-redux";

const {
  ChallengeData: { CHARACTER_BUILDING_CHALLENGE_URL }
} = constants;

export const getColor = name =>
  window
    .getComputedStyle(document.body)
    .getPropertyValue(name)
    .trim();

export const getCharacter = ({ path, user, character, tail = ".svg" }) =>
  `${path}/${user}/${CHARACTER_BUILDING_CHALLENGE_URL}/${character}/${character}${tail}`;
