import Chip from '@material-ui/core/Chip';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import FolderIcon from '@material-ui/icons/Folder';
import React from 'react';
import { socBlue1, socWhite1 } from '../../../style/soc-colors';
import css from './FileChip.module.css';

const iconMap = {
  folder: FolderIcon,
  file: FileIcon
};

const FileChip = ({
  primaryColor = socBlue1,
  secondaryColor = socWhite1,
  label,
  onClick,
  onDelete,
  type
}) => {
  const Icon = iconMap[type];
  return (
    <Chip
      icon={<Icon />}
      onClick={onClick}
      label={label}
      onDelete={onDelete}
      className={css.chip}
      style={{ backgroundColor: primaryColor }}
      // labelColor={secondaryColor}
    />
  );
};

export default FileChip;
