import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import { selectors } from '@school-of-code/soc-redux';

import PrivateRoute from '../../components/router/private-route';
import Navbar from '../../components/global-components/Navbar';
import Pregame from '../../components/global-components/Pregame';
import Game from '../../containers/Game';
import GroupsPage from '../../components/groups-page/GroupsPage';
import RoomPage from '../../components/RoomCreation';
import Login from '../../components/basic-pages/LoginPage';
import PasswordResetEntryPage from '../../components/basic-pages/PasswordResetEntryPage';
import ForgottenPasswordPage from '../../components/basic-pages/ForgottenPasswordPage';
import Profile from '../../components/profile-page/profile-page';
import Register from '../../components/basic-pages/RegisterPage';

const {
  meta: { selectUsername, selectUserRole }
} = selectors;

const Root = ({
  routes,
  username,
  socket,
  onPlayerJoinRoom,
  avatarUrl,
  avatarEditUrl,
  characters,
  stats,
  completedChallenges,
  userRole
}) => (
  <Router>
    <>
      <Navbar />
      <Switch>
        <Route
          path={routes.login}
          render={() => (
            <Login
              registerUrl={routes.register}
              resetUrl={routes.passwordReset}
            />
          )}
        />
        <Route
          path={routes.register}
          render={() => <Register loginUrl={routes.login} />}
        />
        <Route
          path={`${routes.passwordResetEntry}/:passwordResetId`}
          render={({ match }) => (
            <PasswordResetEntryPage
              passwordResetId={match.params.passwordResetId}
              registerUrl={routes.register}
              loginUrl={routes.login}
            />
          )}
        />
        <Route
          path={routes.passwordReset}
          render={() => (
            <ForgottenPasswordPage
              registerUrl={routes.register}
              loginUrl={routes.login}
            />
          )}
        />
        <PrivateRoute
          path={`${routes.game}/:gameId?`}
          component={Game}
          username={username}
          socket={socket}
          onPlayerJoinRoom={onPlayerJoinRoom}
        />
        <PrivateRoute path={routes.groups} component={GroupsPage} />
        <PrivateRoute
          path={routes.profile}
          component={Profile}
          username={username}
          avatarUrl={avatarUrl}
          avatarEditUrl={avatarEditUrl}
          characters={characters}
          stats={stats}
          completedChallenges={completedChallenges}
        />
        <PrivateRoute
          exact
          path={routes.home}
          component={RoomPage}
          userRole={userRole}
          avatarUrl={avatarUrl}
          avatarEditUrl={avatarEditUrl}
          username={username}
        />
        <PrivateRoute
          exact
          path={routes.pregame}
          component={Pregame}
          userRole={userRole}
          // avatarUrl={avatarUrl}
          // avatarEditUrl={avatarEditUrl}
        />
      </Switch>
    </>
  </Router>
);

Root.propTypes = {
  routes: PropTypes.object,
  username: PropTypes.string,
  socket: PropTypes.object,
  onPlayerJoinRoom: PropTypes.func,
  avatarUrl: PropTypes.string,
  avatarEditUrl: PropTypes.string,
  characters: PropTypes.array,
  stats: PropTypes.object,
  completedChallenges: PropTypes.array,
  userRole: PropTypes.string
};

function mapStateToProps(state, ownProps) {
  return {
    userRole: selectUserRole(state),
    username: selectUsername(state),
    avatarUrl: state.meta.avatar
      ? encodeURI(state.meta.avatar)
      : ownProps.awsUrls.defaultCharacterPngUrl,
    ...ownProps
  };
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Root);
