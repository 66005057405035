import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import PreviewWindow from '../../preview-window/PreviewWindow';
import CodeWindow from '../../CodeWindow';
import css from './PlayerCodePreviewWindow.module.css';
let ResizeSensor = require('css-element-queries/src/ResizeSensor');

class PlayerCodePreviewWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerWidth: 0,
      containerHeight: 0
    };
    this.containerDiv = React.createRef();
  }

  componentDidMount() {
    if (this.containerDiv.current) {
      this.setState({
        containerWidth: this.containerDiv.current.clientWidth,
        containerHeight: this.containerDiv.current.clientHeight
      });
      new ResizeSensor(this.containerDiv.current, () => {
        this.setState({
          containerWidth: this.containerDiv.current.clientWidth,
          containerHeight: this.containerDiv.current.clientHeight
        });
      });
    }
  }

  componentWillUnmount() {
    //correct detach method?
    // this.containerDivResizeSensor.detach(this.containerDiv);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.singlePlayerView !== this.props.singlePlayerView) {
      window.requestAnimationFrame(() => {
        window.dispatchEvent(new CustomEvent('resize'));
      });
    }
  }

  render() {
    const {
      playerId,
      userInputs,
      code,
      readOnly,
      currentEditorIndex,
      imports,
      codeResult,
      singlePlayerView,
      handleCodeChange,
      handleEditorTabChange,
      playerStatus
    } = this.props;

    let width = this.state.containerWidth;
    let height = this.state.containerHeight / 2;
    if (singlePlayerView) {
      width = this.state.containerWidth / 2;
      height = this.state.containerHeight;
    }

    return (
      <div
        className={cn({
          [css.playerWindowContentArea]: true,
          [css.layoutArea]: true,
          [css.layoutAreaRow]: singlePlayerView
        })}
        ref={this.containerDiv}
      >
        <div className={css.layoutAreaChild} style={{ width }}>
          <PreviewWindow
            userInputs={userInputs}
            imports={imports}
            code={code}
            codeResult={codeResult}
            containerWidth={width}
            containerHeight={height}
            playerStatus={playerStatus}
          />
        </div>
        <div className={css.layoutAreaChild} style={{ width }}>
          <CodeWindow
            playerId={playerId}
            userInputs={userInputs}
            code={code}
            handleCodeChange={handleCodeChange}
            readOnly={readOnly}
            currentEditorIndex={currentEditorIndex}
            handleEditorTabChange={handleEditorTabChange}
            containerWidth={width}
            containerHeight={height}
          />
        </div>
      </div>
    );
  }
}
PlayerCodePreviewWindow.propTypes = {
  /**
   * Provide the id of the player for this window.
   */
  playerId: PropTypes.number.isRequired,
  /**
   * The language for this task
   */
  userInputs: PropTypes.array.isRequired,
  /**
   * The code to be rendered in the preview window
   */
  code: PropTypes.array.isRequired,
  /**
   * Boolean indicating readonly code window
   */
  readOnly: PropTypes.bool,
  /**
   * Number indicating current editor tab index
   */
  currentEditorIndex: PropTypes.number,
  /**
   * A list of web assets to import in to the preview window
   */
  imports: PropTypes.array,
  /**
   * The result of code compilation
   */
  codeResult: PropTypes.shape({
    err: PropTypes.string,
    out: PropTypes.string
  }),
  /**
   * A handler for code changes
   */
  handleCodeChange: PropTypes.func
};
PlayerCodePreviewWindow.defaultProps = {
  //playerState
  code: [''],
  codeResult: {},
  //playerInfo
  userInputs: [{}],
  imports: []
};

export default PlayerCodePreviewWindow;
