import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ExpandableCard from '../../global-components/ExpandableCard';

import marked from 'marked';
import css from './Information.module.css';

let renderer = new marked.Renderer();

const options = {
  renderer,
  gfm: true,
  tables: true,
  breaks: true,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: false
};
const converter = marked.setOptions(options);
const headingSymbols = '# ';
const rawMarkup = markdown => (
  <div dangerouslySetInnerHTML={{ __html: converter(markdown) }} />
);

const extractCards = (markdown, isInstruction) => {
  if (!markdown) return;
  const markTokens = marked.lexer(markdown);
  let textSplit;
  let cards = [];
  markTokens
    .filter(({ type, depth }) => type === 'heading' && depth === 1)
    .forEach(({ text }, index) => {
      textSplit = markdown.split(`${headingSymbols}${text}`);
      cards.push({ heading: text });
      if (index > 0) {
        cards[index - 1].body = textSplit.shift(); //substr(0, position);
      }
      markdown = textSplit.join('');
    });
  if (cards.length > 0) {
    cards[cards.length - 1].body = markdown;
  } else {
    cards.push({ body: markdown });
  }
  const divClass = cn({
    [css.information]: true,
    [css.informationInstruction]: isInstruction
  });
  return (
    <div>
      {cards.map((card, index) => {
        if (!card) return null;
        if (!card.heading) {
          return (
            <div key={index} className={divClass}>
              {rawMarkup(card.body)}
            </div>
          );
        }
        return (
          <ExpandableCard key={index} title={rawMarkup(card.heading)}>
            <div className={divClass}>{rawMarkup(card.body)}</div>
          </ExpandableCard>
        );
      })}
    </div>
  );
};

const Information = ({ information }) => {
  return (
    <div>
      {extractCards(information.description)}
      {extractCards(information.instructions, true)}
    </div>
  );
};

Information.propTypes = {
  information: PropTypes.shape({
    description: PropTypes.string,
    instructions: PropTypes.string
  })
};

Information.defaultProps = {
  information: {
    description: '',
    instructions: ''
  }
};

export default Information;
