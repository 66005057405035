import React from 'react';

import Badge from '@material-ui/core/Badge';
import css from './NotificationBadge.module.css';

export default ({ value, style = {}, children, ...props }) => {
  if (value < 1) {
    return <div>{children}</div>;
  }
  return (
    <Badge
      {...props}
      badgeContent={value}
      color="secondary"
      className={css.badge}
      style={style}
    >
      {children}
    </Badge>
  );
};
