import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

import { selectors } from "@school-of-code/soc-redux";
const {
  meta: { selectIsAuthenticated }
} = selectors;

const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <Component {...rest} {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  isAuthenticated: selectIsAuthenticated(state)
});

export default connect(mapStateToProps)(PrivateRoute);
