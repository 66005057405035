import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import SwipeableViews from 'react-swipeable-views';

import { constants } from '@school-of-code/soc-redux';

import { socBlue1, socDarkGreyTab } from '../../style/soc-colors';
import CodeEditor from './CodeEditor';

import css from './CodeWindow.module.css';

const { CodeLanguages: codeLanguages } = constants;

const getLanguageDisplay = language => {
  return (
    (codeLanguages[language.toUpperCase()] &&
      codeLanguages[language.toUpperCase()].display) ||
    language
  );
};

const getLanguageColor = language => {
  return (
    (codeLanguages[language.toUpperCase()] &&
      codeLanguages[language.toUpperCase()].color) ||
    socBlue1
  );
};

function CodeWindow({
  playerId,
  userInputs,
  code,
  readOnly,
  currentEditorIndex = 0,
  handleEditorTabChange,
  handleCodeChange,
  containerHeight,
  containerWidth
}) {
  const selectedLanguageColor = userInputs[currentEditorIndex]
    ? getLanguageColor(userInputs[currentEditorIndex].type)
    : socDarkGreyTab;

  return (
    <div className={css.container}>
      <AppBar position="static">
        <Tabs
          TabIndicatorProps={{
            style: { backgroundColor: selectedLanguageColor }
          }}
          value={currentEditorIndex}
          onChange={(event, value) => handleEditorTabChange(value)}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {userInputs.map(({ type }, index) => {
            const displayLanguage = getLanguageDisplay(type);
            const tabLanguageColor = getLanguageColor(type);

            //Sets the properties for unselected tabs.
            const notSelectedStyle = {
              color: tabLanguageColor,
              backgroundColor: socDarkGreyTab,
              borderBottom: `2px solid ${tabLanguageColor}`
            };

            // Sets value isSelected equal to true or false depending on whether the iteration through languages is equal
            // to the current editor index.
            const isTabSelected = index === currentEditorIndex;
            const tabStyle = isTabSelected
              ? { backgroundColor: tabLanguageColor, color: 'white' }
              : notSelectedStyle;

            return (
              <Tab
                classes={{ textColorInherit: css.tab }}
                label={displayLanguage}
                value={index}
                key={index}
                style={tabStyle}
              />
            );
          })}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis="x"
        index={currentEditorIndex}
        onChangeIndex={handleEditorTabChange}
      >
        {userInputs.map(({ type }, index) => {
          const displayLanguage = getLanguageDisplay(type);
          return (
            <CodeEditor
              playerId={playerId}
              editorIndex={index}
              language={displayLanguage}
              code={code[index]}
              handleCodeChange={handleCodeChange}
              height={`${containerHeight - 48}px`}
              width={containerWidth}
              readOnly={readOnly}
              activeIndex={currentEditorIndex}
            />
          );
        })}
      </SwipeableViews>
    </div>
  );
}

CodeWindow.propTypes = {
  /**
   * Provide the id of the player for this window.
   */
  playerId: PropTypes.number.isRequired,
  /**
   * The languages array for this task
   */
  userInputs: PropTypes.array.isRequired,
  /**
   * The code to be displayed in this window
   */
  code: PropTypes.array.isRequired,
  /**
   * Boolean to say if read only window
   */
  readOnly: PropTypes.bool,
  /**
   * Number indicating which is the current index
   */
  currentEditorIndex: PropTypes.number,
  /**
   * Function handling tab change
   */
  handleEditorTabChange: PropTypes.func,
  /**
   * Function handling code change
   */
  handleCodeChange: PropTypes.func
};

CodeWindow.defaultProps = {
  code: [''],
  userInputs: [{}],
  readOnly: false
};

export default CodeWindow;
