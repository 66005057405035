import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { constants } from '@school-of-code/soc-redux';

import PlayerHeader from '../PlayerHeader';
import PlayerCodePreviewWindow from '../PlayerCodePreviewWindow';
import PlayerFooter from '../PlayerFooter';
import css from './PlayerWindow.module.css';

const { PlayerStatuses } = constants;

class PlayerWindow extends Component {
  renderPlayerHeader({ mine, playerName, playerStatus, isConnected }) {
    return (
      <PlayerHeader
        mine={mine}
        playerName={playerName}
        playerStatus={playerStatus}
        isConnected={isConnected}
      />
    );
  }

  renderPlayerFooter({
    mine,
    task,
    playerStatus,
    playerInfo,
    isFinalTask,
    combinedPlayerStatus,
    userInputs,
    saveButtonEnabled,

    handleHintClick,
    handleRunClick,
    handleSubmitClick,
    handleSkipClick,
    handleNextClick,
    handleSaveClick,
    handleLoadClick
  }) {
    if (mine) {
      const test = playerInfo.tests[0];
      const { name: taskName } = task;
      return (
        <PlayerFooter
          playerStatus={playerStatus}
          isFinalTask={isFinalTask}
          test={test}
          combinedPlayerStatus={combinedPlayerStatus}
          taskName={taskName}
          userInputs={userInputs}
          saveButtonEnabled={saveButtonEnabled}
          handleHintClick={handleHintClick}
          handleRunClick={handleRunClick}
          handleSubmitClick={handleSubmitClick}
          handleSkipClick={handleSkipClick}
          handleNextClick={handleNextClick}
          handleSaveClick={handleSaveClick}
          handleLoadClick={handleLoadClick}
        />
      );
    }
    return <div className={css.areaHeading} />;
  }

  componentWillUnmount() {
    //correct detach method?
    // this.containerDivResizeSensor.detach(this.containerDiv);
  }

  render() {
    const {
      playerInfo,
      playerState,
      mine,
      playerName,
      playerId,
      singlePlayerView,
      userInputs,
      readOnly,
      // dispatchers
      handleCodeChange,
      handleEditorTabChange
    } = this.props;
    const { imports } = playerInfo;
    const {
      status: playerStatus,
      code,
      isConnected,
      currentEditorIndex,
      codeResult
    } = playerState;

    return (
      <div className={css.fillHeight}>
        {this.renderPlayerHeader({
          mine,
          playerName,
          playerStatus,
          isConnected
        })}
        <PlayerCodePreviewWindow
          playerId={playerId}
          userInputs={userInputs}
          code={code}
          readOnly={readOnly}
          currentEditorIndex={currentEditorIndex}
          imports={imports}
          codeResult={codeResult}
          singlePlayerView={singlePlayerView}
          handleCodeChange={handleCodeChange}
          handleEditorTabChange={handleEditorTabChange}
          playerStatus={playerStatus}
        />
        {this.renderPlayerFooter(this.props)}
      </div>
    );
  }
}
PlayerWindow.propTypes = {
  mine: PropTypes.bool,
  playerName: PropTypes.string.isRequired,
  playerId: PropTypes.number.isRequired,
  combinedPlayerStatus: PropTypes.string.isRequired,
  isFinalTask: PropTypes.bool,
  task: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    information: PropTypes.shape({
      description: PropTypes.string,
      instructions: PropTypes.string
    })
  }),
  playerInfo: PropTypes.shape({
    imports: PropTypes.array,
    tests: PropTypes.arrayOf(PropTypes.string),
    languages: PropTypes.array
  }),
  playerState: PropTypes.shape({
    code: PropTypes.arrayOf(PropTypes.string).isRequired,
    currentEditorIndex: PropTypes.number,
    status: PropTypes.string,
    isConnected: PropTypes.bool,
    codeResult: PropTypes.shape({
      err: PropTypes.string,
      out: PropTypes.string
    })
  }),
  userInputs: PropTypes.array.isRequired,

  readOnly: PropTypes.bool,

  // dispatchers
  handleCodeChange: PropTypes.func,
  handleHintClick: PropTypes.func,
  handleRunClick: PropTypes.func,
  handleSubmitClick: PropTypes.func,
  handleSkipClick: PropTypes.func
};
PlayerWindow.defaultProps = {
  playerName: '...',
  task: {
    name: ''
  },
  playerInfo: {
    imports: [],
    tests: []
  },
  playerState: {
    status: PlayerStatuses.DEFAULT,
    codeResult: {}
  },
  userInputs: [{}]
};

export default PlayerWindow;
