import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actions } from '@school-of-code/soc-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import PlayerInvitor from '../../global-components/PlayerInvitor';
import css from './InvitePlayerModal.module.css';

const { game: GameActions, modals: ModalsActions } = actions;

class InvitePlayerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stagedPlayers: []
    };
  }

  /**
   * When submitting the invite a player dialog.
   * This function intercepts before passing on to the redux dispatcher.
   * It will grab the playerName from the stagedPlayers and submit that to the
   * redux invite handler
   */
  onSubmitOverride = () => {
    const { stagedPlayers } = this.state;
    if (!stagedPlayers || stagedPlayers.length === 0) {
      return;
    }
    const playerName = stagedPlayers[0].label;
    if (!playerName) {
      return;
    }
    if (this.props.onSubmit) {
      this.props.onSubmit(playerName);
    }
    this.setState({
      stagedPlayers: []
    });
  };

  /**
   * When a user adds a player to the invitor
   * Add this new player to the state
   * TODO: The whole interaction between here and PlayerInvitor can be done better really..
   */
  addToStaged = input => {
    const { stagedPlayers } = this.state;
    this.setState({
      stagedPlayers: stagedPlayers.concat(input)
    });
  };
  /**
   * When a user removes a player from the invitor
   * Remove the specified player from the stagedPlayers state
   * label is the username, and so inherently unique
   */
  removeFromStaged = input => {
    const { stagedPlayers } = this.state;
    this.setState({
      stagedPlayers: stagedPlayers.filter(p => p.label !== input)
    });
  };

  render() {
    const { onClose } = this.props;
    const { stagedPlayers } = this.state;
    return (
      <Dialog
        open
        onClose={onClose}
        PaperProps={{ classes: { root: css.paper } }}
      >
        <DialogTitle classes={{ root: css.title }}>Invite a Friend</DialogTitle>
        <DialogContent classes={{ root: css.content }}>
          <PlayerInvitor
            maxInvites={1}
            stagedPlayers={stagedPlayers}
            handleAddPlayerClick={this.addToStaged}
            handleRemovePlayerClick={this.removeFromStaged}
            message="Search for your friends by typing a username"
            className={css.playerInvitor}
          />
        </DialogContent>

        <DialogActions classes={{ root: css.actions }}>
          <Button onClick={onClose}>No Thanks!</Button>,
          <Button color="primary" onClick={this.onSubmitOverride}>
            Send Invite
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => {
      dispatch(ModalsActions.hideModal());
    },
    onSubmit: playerName => {
      //TODO: Doing it this way doesn't protect against dummy/incorrect/repeated player names..
      //But i guess it doesn't anyway..
      //Maybe addPlayer should have some protection against that generally (right now, it assumes that the name it receives when a player joins a room is genuine.
      //Though, we can have more confidence in that because it is in code we have managed. Here we are relying on user input..
      dispatch(GameActions.invitePlayer({ playerName }));
      dispatch(ModalsActions.hideModal());
    },
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitePlayerModal);
