import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Good from '@material-ui/icons/SentimentVerySatisfied';
import Bad from '@material-ui/icons/SentimentVeryDissatisfied';
import Neutral from '@material-ui/icons/SentimentSatisfied';

import Slider from '@material-ui/core/Slider';

import css from './Feedback.module.css';

export default class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackSliderValue: 5,
      radioButtonValue: '0'
    };
  }

  handleFeedbackSlider = (event, value) => {
    this.setState({ feedbackSliderValue: value });
  };

  handleRadioButtonChange = (event, value) => {
    this.setState({ radioButtonValue: value });
  };

  handleSubmitClick = () => {
    const { feedbackSliderValue, radioButtonValue } = this.state;

    const { handleSubmitClick } = this.props;

    if (isNaN(feedbackSliderValue)) {
      //please select a value
    }
    if (isNaN(radioButtonValue)) {
      //please select a value
    }
    return handleSubmitClick(feedbackSliderValue, radioButtonValue);
  };

  render() {
    const { feedbackSliderValue, radioButtonValue } = this.state;

    const { open, onRequestClose } = this.props;
    return (
      <Dialog open={open} onClose={onRequestClose}>
        <DialogTitle>
          How likely is it that you would recommend this to a friend?
        </DialogTitle>
        <DialogContent>
          <Slider
            value={feedbackSliderValue}
            min={0}
            max={10}
            step={1}
            onChange={this.handleFeedbackSlider}
          />
          <h1 className={css.sliderText}>{feedbackSliderValue}</h1>
          <p>Did you enjoy yourself?</p>

          <RadioGroup
            name="feedback"
            value={radioButtonValue}
            onChange={this.handleRadioButtonChange}
          >
            <FormControlLabel
              value="-1"
              control={<Radio checkedIcon={<Bad />} icon={<Bad />} />}
              label="No"
            />
            <FormControlLabel
              value="0"
              control={<Radio checkedIcon={<Neutral />} icon={<Neutral />} />}
              label="Neutral"
            />
            <FormControlLabel
              value="1"
              control={<Radio checkedIcon={<Good />} icon={<Good />} />}
              label="Yes"
            />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button varirant="text" onClick={onRequestClose}>
            Cancel
          </Button>
          <Button
            varirant="text"
            color="primary"
            onClick={this.handleSubmitClick}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
