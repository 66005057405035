import { connect } from "react-redux";
import { actions, selectors } from "@school-of-code/soc-redux";

import ChatWindow from "../components/chat-window";

const { chat: ChatActions } = actions;
const {
  chat: { selectMessages, selectIsMaximised, selectUnseenMessages },
  game: {
    selectNumberOfConnectedPlayers,
    selectThisPlayerId,
    selectRoomName,
    selectThisPlayerName
  }
} = selectors;

const mapStateToProps = (state, ownProps) => {
  const thisPlayerId = selectThisPlayerId(state);
  return {
    thisPlayerId,
    // chat
    messages: selectMessages(state),
    isMaximised: selectIsMaximised(state),
    unseenMessages: selectUnseenMessages(state),
    // game
    roomName: selectRoomName(state),
    numberOfConnectedPlayers: selectNumberOfConnectedPlayers(state),
    playerName: selectThisPlayerName(state, thisPlayerId),
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  };
};

const mergeProps = (stateProps, dispatchProps) => {
  const { playerName, thisPlayerId } = stateProps;
  const { dispatch } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    handleMessageSubmit: text => {
      dispatch(ChatActions.addMessage(playerName, thisPlayerId, text));
    },
    onMaximise: () => dispatch(ChatActions.maximiseChat()),
    onMinimise: () => dispatch(ChatActions.minimiseChat())
  };
};

const ChatWindowContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ChatWindow);

export default ChatWindowContainer;
