import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import css from '../Form.module.css';

class PasswordResetEntryForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      password: '',
      errorText: '',
      passwordErrorText: ''
    };
  }

  handlePasswordChange = event => {
    return this.setState({
      password: event.target.value
    });
  };

  submitForm = () => {
    const { onSubmit } = this.props;
    const { password } = this.state;
    if (!this.checkFormReady()) {
      return;
    }
    return onSubmit({
      password
    });
  };

  checkFormReady = () => {
    const { password, passwordErrorText } = this.state;
    if (!password) {
      this.setState({
        errorText: 'Password field cannot be left empty :('
      });
      return false;
    }
    if (passwordErrorText !== '') {
      this.setState({
        errorText: 'Fix the errors in the form first :)'
      });
      return false;
    }
    this.setState({
      errorText: ''
    });
    return true;
  };

  render() {
    const { password, errorText, passwordErrorText } = this.state;
    return (
      <form>
        <TextField
          id="password"
          value={password}
          onChange={this.handlePasswordChange}
          placeholder="Type in your password here"
          label="Password"
          type="password"
          fullWidth
          errorText={passwordErrorText}
        />
        <div className={css.error}>{errorText}</div>
        <Button
          color="primary"
          onClick={this.submitForm}
          className={css.button}
        >
          Reset Password
        </Button>
      </form>
    );
  }
}

PasswordResetEntryForm.contextTypes = {
  routes: PropTypes.object,
  api: PropTypes.object
};

export default PasswordResetEntryForm;
