import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import cn from 'classnames';

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';

import css from './room-list-container.module.css';

const renderList = (list, separator = ' ', style = {}) => {
  if (!list || list.length === 0) {
    return '-';
  }
  const length = list.length;
  return list.map((item, index) => {
    const insertSeparator =
      index < length - 1 ? <span>{separator}</span> : null;
    const insertItem = item ? item : '-';
    return (
      <span key={index} className={css.text}>
        {insertItem}
        {insertSeparator}
      </span>
    );
  });
};

class RoomTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toPlay: null,
      page: 0,
      rowsPerPage: 5
    };
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  onSelect = toPlay => {
    this.setState(() => ({ toPlay }));
  };

  goToGame = () => {
    //TODO: from config/constant
    this.props.history.push(`/game/${this.state.toPlay}`);
  };

  render() {
    const { height = '300px', tableData = [] } = this.props;
    const { toPlay, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, tableData.length - page * rowsPerPage);

    return (
      <Paper classes={{ root: css.paper }}>
        <div>
          <Table height={height}>
            <TableHead>
              <TableRow classes={{ root: css.iconHeader }}>
                <TableCell tooltip="Game Mode" classes={{ root: css.iconH }}>
                  Game Mode
                </TableCell>
                <TableCell tooltip="Players" classes={{ root: css.iconH }}>
                  Players
                </TableCell>
                <TableCell tooltip="Languages" classes={{ root: css.iconH }}>
                  Languages
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(({ id, challengeName, playerNames, languages }, index) => {
                  const isSelected = id === toPlay;
                  return (
                    <TableRow
                      key={index}
                      className={cn(css.row, {
                        [css.selected]: isSelected
                      })}
                      onClick={() => this.onSelect(id)}
                    >
                      <TableCell className={css.center}>
                        <span className={css.text}>{challengeName}</span>
                      </TableCell>
                      <TableCell className={css.center}>
                        <span className={css.text}>
                          {renderList(playerNames, ', ', css.players)}
                        </span>
                      </TableCell>
                      <TableCell className={cn(css.center, css.last)}>
                        <span className={css.text}>
                          {renderList(languages, ', ', css.languageItems)}
                        </span>
                        <Button
                          variant="contained"
                          className={cn(css.joinButton, {
                            [css.popIn]: isSelected
                          })}
                          onClick={this.goToGame}
                        >
                          Join
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={3} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page'
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page'
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    );
  }
}

export default withRouter(RoomTable);
