import React from 'react';
import PropTypes from 'prop-types';
import css from './AreaHeader.module.css';

const AreaHeader = ({ heading }) => {
  return <div className={css.areaHeading}>{heading}</div>;
};
AreaHeader.propTypes = {
  title: PropTypes.string
};
AreaHeader.defaultProps = {
  title: ''
};

export default AreaHeader;
