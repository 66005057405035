import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from '@material-ui/core/Button';
import ChatIcon from '@material-ui/icons/Chat';

import Header from './Header';
import MessageList from './MessageList';
import Footer from './Footer';
import NotificationBadge from '../global-components/NotificationBadge';
import css from './ChatWindow.module.css';

const ChatWindow = ({
  messages,
  isMaximised,
  unseenMessages,
  handleMessageSubmit,
  roomName,
  numberOfConnectedPlayers,
  thisPlayerId,
  onMaximise,
  onMinimise
}) => {
  return (
    //TODO: make controlled so that we can readjust when maximising and minimising
    <div>
      {isMaximised ? (
        <div className={css.chatWindowContainer}>
          <div className={css.chatHeader}>
            <Header
              roomName={roomName}
              numberOfConnectedPlayers={numberOfConnectedPlayers}
              onMinimise={onMinimise}
              onMaximise={onMaximise}
              unseenMessages={unseenMessages}
            />
          </div>
          <div className={css.chatWindowMainArea}>
            <MessageList messages={messages} thisPlayerId={thisPlayerId} />
          </div>
          <div className={css.chatFooter}>
            <Footer onMessageSubmit={handleMessageSubmit} />
          </div>
        </div>
      ) : (
        <div className={cn(css.chatMinimised, 'drag-handle')}>
          <NotificationBadge value={unseenMessages}>
            <Button
              variant="contained"
              onClick={onMaximise}
              className={css.chatButton}
            >
              <ChatIcon className={css.chatIcon} />
            </Button>
          </NotificationBadge>
        </div>
      )}
    </div>
  );
};
ChatWindow.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      playerId: PropTypes.number,
      playerName: PropTypes.string,
      text: PropTypes.string
    })
  ).isRequired,
  handleMessageSubmit: PropTypes.func.isRequired,
  roomName: PropTypes.string.isRequired,
  numberOfConnectedPlayers: PropTypes.number.isRequired
};
ChatWindow.defaultProps = {
  messages: [],
  roomName: 'school-of-code',
  numberOfConnectedPlayers: 0
};

export default ChatWindow;
