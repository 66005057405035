import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import PropTypes from 'prop-types';
import { actions } from '@school-of-code/soc-redux';

import Button from '@material-ui/core/Button';
import CharacterImage from '../global-components/CharacterImage';
import EditAvatarModal from '../modals/EditAvatarModal';

import css from './profile-picture.module.css';

const { modals: ModalsActions, game: GameActions } = actions;

class ProfilePicture extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, hovering: false };
  }

  componentDidMount() {
    this.props.grabAvatar();
  }
  handleOpen = () => {
    this.setState({ open: true });
    this.props.handleLoadClick();
  };
  handleClose = () => this.setState({ open: false });
  handleHoverIn = () => this.setState({ hovering: true });
  handleHoverOut = () => this.setState({ hovering: false });
  render() {
    const {
      imageURL,
      backgroundColour,
      characters,
      username,
      handleSubmit,
      avatar
    } = this.props;
    const { open, hovering } = this.state;
    return (
      <div
        className={cn(css.frameStyle, 'tooltip')}
        style={{ backgroundColour }}
        onMouseEnter={this.handleHoverIn}
        onMouseLeave={this.handleHoverOut}
      >
        <CharacterImage
          className={cn(css.pictureStyle, 'image')}
          src={avatar ? avatar : imageURL}
        />

        <Button
          variant="contained"
          className={cn(css.editAvatar, {
            [css.popIn]: hovering
          })}
          onClick={this.handleOpen}
        >
          Select Avatar
        </Button>
        <EditAvatarModal
          characters={characters}
          open={open}
          username={username}
          handleDismissClick={this.handleClose}
          handleSubmit={handleSubmit}
        />
      </div>
    );
  }
}

ProfilePicture.propTypes = {
  backgroundColour: PropTypes.string,
  imageURL: PropTypes.string
};

ProfilePicture.defaultProps = {
  backgroundColour: '#f5f5f5'
};

const mapStateToProps = (state, ownProps) => {
  return {
    characters: state.modals.modalProps.options,
    username: state.meta.username,
    avatar: state.meta.avatar
      ? encodeURI(state.meta.avatar)
      : ownProps.avatarUrl,
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleLoadClick: () => {
      dispatch(ModalsActions.loadCharacters());
    },
    grabAvatar: () => {
      dispatch(GameActions.grabAvatar());
    },
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps) => {
  const { dispatch } = dispatchProps;
  const { characters } = stateProps;
  return {
    ...stateProps,
    ...dispatchProps,
    handleSubmit: (index, url) => {
      const selectedAvatar = characters[index];
      dispatch(GameActions.setAvatar({ name: selectedAvatar, url }));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ProfilePicture);
