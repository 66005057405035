import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import CharacterChip from '../../global-components/CharacterChip';

import { getColor } from '../../../utils';
import css from './EditAvatarModal.module.css';

class EditAvatarModal extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: 0 };
  }
  handleSelect = i => url => {
    this.setState({ selected: i, url });
  };
  render() {
    const {
      open,
      handleDismissClick,
      characters,
      username,
      handleSubmit
    } = this.props;
    return (
      <Dialog
        onClose={handleDismissClick}
        open={open}
        PaperProps={{ classes: { root: css.paper } }}
      >
        <DialogTitle classes={{ root: css.title }}>
          Choose Your Avatar
        </DialogTitle>
        <DialogContent classes={{ root: css.content }}>
          {characters &&
            characters.map((label, key) => {
              const colors =
                this.state.selected === key
                  ? {
                      primary: getColor('--mainBlue'),
                      background: getColor('--lightWhite')
                    }
                  : {
                      primary: getColor('--grey'),
                      background: getColor('--black')
                    };

              return (
                <CharacterChip
                  key={key}
                  label={label}
                  primaryColor={colors.primary}
                  secondaryColor={colors.background}
                  onDelete={null}
                  onClick={this.handleSelect(key)}
                  // //TODO: change this, temp to make work
                  user={username}
                  character={label}
                />
              );
            })}
        </DialogContent>
        <DialogActions classes={{ root: css.actions }}>
          <Button onClick={handleDismissClick}>No thanks!</Button>
          <Button
            onClick={() => {
              handleSubmit(this.state.selected, this.state.url);
              handleDismissClick();
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

EditAvatarModal.propTypes = {
  open: PropTypes.bool,
  handleDismissClick: PropTypes.func,
  characters: PropTypes.array,
  username: PropTypes.string,
  handleSubmit: PropTypes.func
};

export default EditAvatarModal;
