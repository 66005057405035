import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import css from './HorizontalImageSlider.module.css';

const HorizontalImageSlider = ({
  sliderWidth,
  sliderHeight,
  imageWidth,
  imageHeight,
  margin,
  sliderImages,
  sliderSettings
}) => {
  const horizontalSliderStyle = {
    sliderWidth,
    sliderHeight,
    margin
  };

  const imageStyle = {
    width: imageWidth,
    height: imageHeight
  };

  const renderImages = images => {
    return images.map((image, key) => {
      return (
        <div className={css.container} key={key}>
          <a href={image.playUrl}>
            <img
              style={imageStyle}
              className={css.image}
              src={image.url}
              alt={`Slide #${key}`}
            />
            <h4 className={css.label}>{image.label}</h4>
          </a>
        </div>
      );
    });
  };

  return (
    <div style={horizontalSliderStyle}>
      <Slider {...sliderSettings}>{renderImages(sliderImages)}</Slider>
    </div>
  );
};

HorizontalImageSlider.propTypes = {
  sliderWidth: PropTypes.string,
  sliderHeight: PropTypes.string,
  margin: PropTypes.string,
  imageMargin: PropTypes.string,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  sliderImages: PropTypes.array.isRequired
};

HorizontalImageSlider.defaultProps = {
  sliderWidth: '90%',
  sliderHeight: 'auto',
  imageHeight: '80px',
  imageMargin: '10px'
};

export default HorizontalImageSlider;
