import React from 'react';
import PropTypes from 'prop-types';
import { constants } from '@school-of-code/soc-redux';

const { CssConstants: css } = constants;

const PlayerLevel = ({ level, size, innerAlign, URL, isShown }) => {
  var align = '';
  if (innerAlign === css.CENTER) {
    align = 'auto';
  } else {
    align = 'null';
  }

  const levelContainerStyle = {
    width: size + size * 0.6 + 'px',
    height: size + 'px',
    backgroundImage: 'url(' + URL + ')',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: align,
    visibility: isShown
  };

  const levelValueStyle = {
    fontSize: '30px',
    color: 'white',
    textAlign: 'center',
    position: 'relative',
    top: '50%',
    transform: 'translateY(-100%)',
    fontFamily: 'Arial'
  };

  return (
    <div className="player-level" style={levelContainerStyle}>
      <h2 style={levelValueStyle}>{level}</h2>
    </div>
  );
};

PlayerLevel.propTypes = {
  level: PropTypes.number.isRequired,
  size: PropTypes.number,
  align: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  isShown: PropTypes.string
};

PlayerLevel.defaultProps = {
  level: 0,
  size: 60,
  align: css.CENTER,
  fontSize: '20px',
  URL: '/images/level_bg.png',
  isShown: 'visible'
};

export default PlayerLevel;
