import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { actions } from '@school-of-code/soc-redux';

import PasswordResetEntryForm from '../PasswordResetEntryForm';
import css from '../Page.module.css';

const { meta: MetaActions } = actions;

const PasswordResetEntryPage = ({
  passwordResetId,
  handleSubmitForm,
  registerUrl,
  loginUrl
}) => (
  <Paper className={css.container} elevation={1}>
    <PasswordResetEntryForm onSubmit={handleSubmitForm} />
    <hr />
    <div className={css.footnote}>
      Not got an account yet? <Link to={registerUrl}>Register</Link>
      <span className={css.separator}>|</span>
      Remember your password? <Link to={loginUrl}>Login</Link>
    </div>
  </Paper>
);

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps) => {
  const { passwordResetId } = stateProps;
  const { dispatch } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    handleSubmitForm: ({ password }) => {
      dispatch(MetaActions.resetPassword({ password, passwordResetId }));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(PasswordResetEntryPage)
);
