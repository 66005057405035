import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from '@school-of-code/soc-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import CharacterChip from '../../global-components/CharacterChip';
import { getColor } from '../../../utils';

import css from './FriendsModal.module.css';

const { meta: MetaActions } = actions;

class FriendsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      friend: '',
      selected: []
    };
  }

  componentDidMount() {
    this.props.getFriendsInfo();
  }

  handleChange = e => {
    const { name, value } = e.currentTarget;
    this.setState(state => ({
      [name]: value
    }));
  };

  handleSelect = (_, i) =>
    this.setState(state => ({ ...state, selected: [...state.selected, i] }));

  handleAcceptClick = () => {
    const { requests } = this.props;
    const { selected } = this.state;
    const acceptedRequests = requests.filter((r, i) => selected.includes(i));
    this.props.acceptFriendRequests(acceptedRequests);
  };

  dismissOverride = () => {
    this.props.handleDismissClick();
    this.resetField();
    this.props.resetFriendError();
  };

  resetField = () => this.setState(state => ({ ...state, friend: '' }));

  render() {
    const {
      open,
      handleDismissClick,
      sendFriendRequest,
      sent,
      requests,
      friends,
      authenticatedUser,
      declineFriendRequest,
      friendError
    } = this.props;
    const { friend, selected } = this.state;
    return (
      <Dialog
        onClose={handleDismissClick}
        open={open}
        PaperProps={{ classes: { root: css.paper } }}
      >
        <DialogTitle classes={{ root: css.title }}>Friends</DialogTitle>
        <DialogContent classes={{ root: css.content }}>
          <div className={css.friendsContainer}>
            <h4>View your friends here</h4>
            {friends.map(({ username, avatar }, i) => (
              <CharacterChip
                key={i}
                index={i}
                label={username}
                onDelete={null}
                user={authenticatedUser}
                character={username}
                friends
                imgUrl={avatar}
              />
            ))}
            {friends.length === 0 && (
              <span className={css.span}>
                You haven't made any friends yet!
              </span>
            )}
          </div>
          <div className={css.requestsContainer}>
            <h4>View your friend requests</h4>
            {requests.map(({ username, avatar }, i) => {
              const colors = selected.includes(i)
                ? {
                    primary: getColor('--mainBlue'),
                    background: getColor('--lightWhite')
                  }
                : {
                    primary: getColor('--grey'),
                    background: getColor('--black')
                  };
              return (
                <CharacterChip
                  key={i}
                  index={i}
                  label={username}
                  primaryColor={colors.primary}
                  secondaryColor={colors.background}
                  onDelete={() => declineFriendRequest(username)}
                  onClick={this.handleSelect}
                  // //TODO: change this, temp to make work
                  user={authenticatedUser}
                  character={username}
                  friends
                  imgUrl={avatar}
                />
              );
            })}
            {selected.length !== 0 && (
              <Button onClick={this.handleAcceptClick}>Accept</Button>
            )}
            {requests.length === 0 && (
              <span className={css.span}>
                You haven't got any friend requests yet!
              </span>
            )}
          </div>
          <div className={css.sendContainer}>
            <h4>Send a request here</h4>
            <TextField
              placeholder="Enter your friend's name"
              value={friend}
              onChange={this.handleChange}
              name="friend"
              fullWidth
            />
            {friendError && <span style={{ color: 'red' }}>{friendError}</span>}
            <Button
              onClick={() => {
                this.resetField();
                sendFriendRequest(friend);
              }}
              color="primary"
            >
              Send
            </Button>
          </div>
          <div className={css.pendingContainer}>
            <h4>View the requests you have sent</h4>
            {sent.map(({ username, avatar }, i) => (
              <CharacterChip
                key={i}
                index={i}
                label={username}
                onDelete={null}
                user={authenticatedUser}
                character={username}
                friends
                imgUrl={avatar}
              />
            ))}
            {sent.length === 0 && (
              <span className={css.span}>
                You haven't sent any requests yet!
              </span>
            )}
          </div>
        </DialogContent>
        <DialogActions classes={{ root: css.actions }}>
          <Button onClick={this.dismissOverride}>No thanks!</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

FriendsModal.propTypes = {
  open: PropTypes.bool,
  handleDismissClick: PropTypes.func,
  sendFriendRequest: PropTypes.func,
  sent: PropTypes.array,
  requests: PropTypes.array,
  friends: PropTypes.array,
  authenticatedUser: PropTypes.string,
  declineFriendRequest: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    authenticatedUser: state.meta.username,
    friends: state.meta.friends,
    requests: state.meta.requests,
    sent: state.meta.sent,
    friendError: state.meta.friendError
  };
};

const mapDispatchToProps = dispatch => {
  const token = localStorage.getItem('soc-token');
  return {
    getFriendsInfo: () => {
      dispatch(MetaActions.refreshFriends({ token }));
    },
    sendFriendRequest: name => {
      dispatch(MetaActions.sendFriendRequest({ token, name }));
    },
    acceptFriendRequests: accepted => {
      accepted.forEach(r =>
        dispatch(MetaActions.acceptFriendRequest({ token, name: r.username }))
      );
    },
    declineFriendRequest: name => {
      dispatch(MetaActions.declineFriendRequest({ token, name }));
    },
    resetFriendError: () => {
      dispatch(MetaActions.resetFriendError());
    },
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FriendsModal);

// export default FriendsModal;
