import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import { actions } from '@school-of-code/soc-redux';

import ForgottenPasswordForm from '../ForgottenPasswordForm';
import css from '../Page.module.css';

const { meta: MetaActions } = actions;

const ForgottenPasswordPage = ({
  handleSubmitForm,
  registerUrl,
  loginUrl,
  apiMessage,
  metaError
}) => (
  <Paper className={css.container} elevation={1}>
    <ForgottenPasswordForm
      onSubmit={handleSubmitForm}
      apiMessage={apiMessage}
      metaError={metaError}
    />
    <hr />
    <div className={css.footnote}>
      Not got an account yet? <Link to={registerUrl}>Register</Link>
      <span className={css.separator}>|</span>
      Remember your password? <Link to={loginUrl}>Login</Link>
    </div>
  </Paper>
);

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    metaError: state.meta.metaError,
    apiMessage: state.meta.apiMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleSubmitForm: ({ email }) => {
      dispatch(MetaActions.forgottenPassword(email));
    },
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgottenPasswordPage);
