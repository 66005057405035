import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

import CharacterChip from '../../global-components/CharacterChip';

import TextInputDialog from '../../global-components/TextInputDialog';

import { getColor } from '../../../utils';

import css from '../Modal.module.css';

//TODO: need to handle the error and waiting cases, move to a more classic promise chain for dispatching the actions and handling results

const renderCharacters = ({
  error,
  options,
  user,
  onChange,
  chosen,
  filter,
  onSelect
}) => {
  if (error) {
    return <p className={css.error}>{error}</p>;
  }

  if (!options) {
    return (
      <div className={css.container}>
        <p>Loading...</p>
        <LinearProgress color="primary" className={css.refresh} />
      </div>
    );
  }
  let filteredOptions = !filter
    ? options
    : options.filter(o => {
        const regex = new RegExp(filter);
        return o.match(regex);
      });
  return filteredOptions.map((label, key) => {
    const colors =
      chosen === label
        ? {
            primary: getColor('--mainBlue'),
            background: getColor('--lightWhite')
          }
        : {
            primary: getColor('--grey'),
            background: getColor('--black')
          };

    return (
      <CharacterChip
        key={key}
        label={label}
        primaryColor={colors.primary}
        secondaryColor={colors.background}
        onDelete={null}
        onClick={() => onSelect(label)}
        //TODO: change this, temp to make work
        user={user}
        character={label}
      />
    );
  });
};

const onSubmitOverride = (name, onSuccess, onError) => {
  let nameCheck = name.trim();
  if (!nameCheck) {
    return onError('Please enter a name');
    //TODO: could we generalise and hand a validate method?
  } else if (!nameCheck.match(/^[a-z0-9]+$/)) {
    return onError(
      'Please enter a valid name - only use lowercase letters and numbers'
    );
  }
  return onSuccess(nameCheck);
};

const CharacterChooserModal = ({
  onClose,
  onSubmit,
  onChange,
  onError,
  options,
  user,
  chosen,
  error,
  inputError,
  filter,
  onSelect,
  modalType
}) => (
  <TextInputDialog
    open={true}
    onSubmit={text => onSubmitOverride(text, onSubmit, onError)}
    onRequestClose={onClose}
    scrollable={true}
    onTextChange={onChange}
    name={filter}
    chosen={chosen}
    inputError={inputError}
    type="character"
    modalType={modalType}
  >
    {renderCharacters({
      error,
      options,
      user,
      onChange,
      chosen,
      filter,
      onSelect
    })}
  </TextInputDialog>
);

export default CharacterChooserModal;
