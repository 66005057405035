import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';

import { actions } from '@school-of-code/soc-redux';

import CharacterList from '../../global-components/CharacterList';

const { modals: ModalsActions } = actions;

class GoPlayModal extends Component {
  render() {
    const { onClose } = this.props;
    return (
      <Dialog
        title="Go Play"
        open
        onClose={onClose}
        PaperProps={{
          style: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            width: '60vw',
            padding: '1rem'
          }
        }}
      >
        <h2 style={{ margin: '2rem' }}>
          Pick an avatar to play with, or just click away to make some more...
        </h2>
        <CharacterList {...this.context} />
      </Dialog>
    );
  }
}

GoPlayModal.contextTypes = {
  routes: PropTypes.object,
  getUserCharacterSvgUrl: PropTypes.func,
  defaultCharacterPngUrl: PropTypes.string
};

function mapStateToProps(state, ownProps) {
  return ownProps;
}

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => {
      dispatch(ModalsActions.hideModal());
    },
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoPlayModal);
