import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { sendRequest } from '../../../libs/utils/requests';
import css from './addroom.module.css';

const style = {
  playerInvitor: {
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  description: { marginTop: '10px' }
};

export default class AddRoomDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedChallengeIndex: 0,
      challenges: [],
      stagedPlayers: []
    };
  }

  componentDidMount() {
    this.fetchChallenges();
  }

  fetchChallenges = () => {
    const fetchOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const destination = this.context.api.challenges;
    sendRequest({
      destination,
      options: fetchOptions,
      success: response => {
        this.setState({
          challenges: response.payload.challenges
        });
      },
      error: err => {
        console.error('error retrieving challenges', err);
      }
    });
  };

  handleSelectFieldChange = e => {
    e.preventDefault();
    const { value } = e.target;
    this.setState(state => ({
      selectedChallengeIndex: state.challenges.findIndex(
        itm => itm.name === value
      )
    }));
  };

  submitClickOverride = e => {
    e.preventDefault();
    const { challenges, selectedChallengeIndex, stagedPlayers } = this.state;
    const { handleSubmitClick } = this.props;
    const selectedChallenge = challenges[selectedChallengeIndex];
    const challengeName = selectedChallenge
      ? selectedChallenge.name
      : undefined;
    const playerNames = stagedPlayers.map(player => player.label);
    this.createRoom(challengeName, playerNames);
    this.setState(() => ({
      stagedPlayers: []
    }));
    handleSubmitClick();
  };

  /**
   * When a user adds a player to the invitor
   * Add this new player to the state
   * TODO: The whole interaction between here and PlayerInvitor can be done better really..
   */
  addPlayerClickOverride = player =>
    this.setState(state => ({
      stagedPlayers: [...state.stagedPlayers, player]
    }));

  /**
   * When a user removes a player from the invitor
   * Remove the specified player from the stagedPlayers state
   */
  removeStagedPlayer = key => {
    const { stagedPlayers } = this.state;
    //find where this key is in the array
    const delIndex = stagedPlayers.findIndex(player => player.key === key);
    //remove that item from the array
    this.setState(state => ({
      stagedPlayers: [
        ...stagedPlayers.slice(0, delIndex),
        ...stagedPlayers.slice(delIndex + 1)
      ]
    }));
  };

  /**
   * Post to api to create a room
   */
  createRoom = (challengeName, playerNames) => {
    const fetchOptions = {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        challengeName,
        playerNames
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const destination = this.context.api.rooms;

    sendRequest({
      destination,
      options: fetchOptions,
      success: () => {
        console.log('Room created');
      },
      error: err => {
        console.error('Error creating room', err);
      }
    });
  };

  render() {
    const { open, handleDismissClick } = this.props;

    const { challenges, selectedChallengeIndex } = this.state;

    const { description = '', name = '' } =
      challenges[selectedChallengeIndex] || {};

    const actions = [
      <Button variant="contained" onClick={handleDismissClick}>
        <span>Dismiss</span>
      </Button>,
      <Button
        variant="contained"
        color="secondary"
        onClick={this.submitClickOverride}
      >
        <span className={css.text}>Submit</span>
      </Button>
    ];

    const challengeMenuItems = challenges.map((challenge, i) => (
      <MenuItem value={challenge.name} key={i}>
        {challenge.name}
      </MenuItem>
    ));

    return (
      <Dialog open={open} PaperProps={{ classes: { root: css.paper } }}>
        <DialogTitle disableTypography classes={{ root: css.title }}>
          Add Room
        </DialogTitle>
        <DialogContent classes={{ root: css.content }}>
          <div className={css.challenge}>
            <h4>Select a challenge</h4>
            <Select
              value={name}
              onChange={this.handleSelectFieldChange}
              className={css.select}
            >
              {challengeMenuItems}
            </Select>
            <p style={style.description}>{description}</p>
          </div>
        </DialogContent>
        <DialogActions classes={{ root: css.actions }}>{actions}</DialogActions>
      </Dialog>
    );
  }
}

AddRoomDialog.propTypes = {
  /**
   * Is this dialog open or not?
   */
  open: PropTypes.bool,
  /**
   * function to handle submit
   */
  handleSubmitClick: PropTypes.func,
  /**
   * function to handle dismiss
   */
  handleDismissClick: PropTypes.func
};

AddRoomDialog.defaultProps = {
  open: false
};

AddRoomDialog.contextTypes = {
  routes: PropTypes.object,
  api: PropTypes.object
};
