import React from 'react';

import Container from '../global-components/Container';
import ProfileHeader from '../profile-page/profile-header';
import RoomTableContainer from './RoomTableContainer';

import css from './RoomPage.module.css';

const RoomPage = ({ userRole, avatarUrl, avatarEditUrl, username }) => (
  <div className={css.container}>
    <div className={css.top}>
      <div className={css.kickstarterContent}>
        <h1>{`Welcome to the School of Code platform, ${username}!`}</h1>
      </div>
      <ProfileHeader
        width="100%"
        height="100%"
        avatarUrl={avatarUrl}
        avatarEditUrl={avatarEditUrl}
      />
    </div>
    <Container>
      <RoomTableContainer userRole={userRole} />
    </Container>
  </div>
);

export default RoomPage;
