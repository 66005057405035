import React from 'react';
import PropTypes from 'prop-types';

import characterBuildingRenderContent from '@school-of-code/character-converter';
import { constants } from '@school-of-code/soc-redux';

import PreviewWindowBase from '../PreviewWindowBase';
import consoleRender from '../ConsoleRender';
import webRenderContent from '../WebRenderContent';
import renderIFrame from '../RenderIframe';

import css from './PreviewWindow.module.css';

const { CodeLanguages } = constants;

const consolePreviewConstant = 'consolePreview';
const characterPreviewConstant = 'characterPreview';
const webPreviewConstant = 'webPreview';

const components = {
  [consolePreviewConstant]: consoleRender(PreviewWindowBase),
  [webPreviewConstant]: renderIFrame(PreviewWindowBase, webRenderContent),
  [characterPreviewConstant]: renderIFrame(
    PreviewWindowBase,
    characterBuildingRenderContent
  )
};

function choosePreviewWindow(types) {
  if (!types) {
    return null;
  }
  if (
    types.includes(CodeLanguages.CSS.type) ||
    types.includes(CodeLanguages.HTML.type)
  ) {
    if (types.includes(CodeLanguages.CHARACTER.type)) {
      return characterPreviewConstant;
    }
    return webPreviewConstant;
  }
  return consolePreviewConstant;
}

function PreviewWindow({ userInputs, imports, codeResult, playerStatus }) {
  function renderPreviewWindow() {
    const inputTypes = userInputs.map(u => u.type);
    const previewWindowType = choosePreviewWindow(inputTypes);
    const Preview = components[previewWindowType];
    // if(!Preview) {return null;}
    switch (previewWindowType) {
      case characterPreviewConstant:
      case webPreviewConstant:
        const newImports = imports.map(i => {
          return { type: 'svg', content: i };
        });
        return <Preview imports={newImports} userInputs={userInputs} />;
      case consolePreviewConstant:
      default:
        return <Preview codeResult={codeResult} playerStatus={playerStatus} />;
    }
  }

  return <div className={css.container}>{renderPreviewWindow()}</div>;
}

PreviewWindow.propTypes = {
  userInputs: PropTypes.array.isRequired,
  /**
   * The code to be rendered in the preview window
   */
  code: PropTypes.array.isRequired,
  /**
   * A list of web assets to import in to the preview window
   */
  imports: PropTypes.array,
  /**
   * The result of code compilation
   */
  codeResult: PropTypes.shape({
    err: PropTypes.string,
    out: PropTypes.string
  })
};
PreviewWindow.defaultProps = {
  //playerState
  code: [''],
  codeResult: {},
  //playerInfo
  userInputs: {},
  imports: []
};

export default PreviewWindow;
