import React from "react";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import UserAdd from "@material-ui/icons/PersonAdd";

const PlayerInviteButton = ({ handleInvitePlayerClick }) => {
  return (
    <IconButton onClick={handleInvitePlayerClick}>
      <UserAdd />
    </IconButton>
  );
};

PlayerInviteButton.propTypes = {
  handleInvitePlayerClick: PropTypes.func.isRequired
};

export default PlayerInviteButton;
