import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, Route } from "react-router-dom";

import { sendRequest } from "../../../libs/utils/requests";

import Button from "@material-ui/core/Button";
import UpdateIcon from "@material-ui/icons/Update";
import PlusIcon from "@material-ui/icons/AddCircle";
import ConfirmIcon from "@material-ui/icons/Done";
import CancelIcon from "@material-ui/icons/Clear";

import AddGroupDialog from "../AddGroupDialog";
import GroupTable from "../BaseTable";

import { socWhite1 } from "../../../style/soc-colors";

//rendering functions
const renderAddGroupButton = onClick => (
  <Button onClick={onClick} fullWidth>
    Create A Group <PlusIcon />
  </Button>
);

const getTableData = (groups, deleteGroup, url) => {
  const data = groups.map(group => {
    const { members, requests, name } = group;
    const memberButton = member => (
      <Link to={`${url}/${name}/members/${member}`}>{member}</Link>
    );
    const requestButton = request => (
      <Link to={`${url}/${name}/requests/${request}`}>{request}</Link>
    );
    const deleteButton = <Link to={`${url}/${name}/delete`}>Delete</Link>;
    const membersList = members.map(m => memberButton(m));
    const requestList = requests.map(r => requestButton(r));
    return [name, membersList, requestList, deleteButton];
  });
  return {
    table: {
      data
    },
    headers: ["name", "members", "requests", "delete"]
  };
};

class GroupTableContainer extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      groups: [],
      addGroupDialogOpen: false
    };
  }

  componentDidMount() {
    this.fetchGroups();
  }

  //TODO: see if we can do shouldReRender only if group names have changed

  //methods
  fetchGroups = () => {
    const fetchOptions = {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    };
    //TODO: go through app and tie these to config file
    const destination = this.context.api.groups;
    sendRequest({
      destination,
      options: fetchOptions,
      success: response => {
        this.setState({
          groups: response.payload.groups
        });
      },
      error: err => {
        console.error("error retrieving groups", err);
      }
    });
  };

  declineRequest = ({ groupId, requestId }) => {
    const fetchOptions = {
      method: "DELETE",
      credentials: "include",
      body: JSON.stringify({
        requestId
      }),
      headers: {
        "Content-Type": "application/json"
      }
    };
    //TODO: go through app and tie these to config file
    const destination = `${
      this.context.api.groups
    }/${groupId}/requests/${requestId}`;

    sendRequest({
      destination,
      options: fetchOptions,
      success: () => {
        this.fetchGroups();
        this.context.router.history.push(`/groups/list`);
      },
      error: err => {
        console.error("error changing request", err);
        this.context.router.history.push(`/groups/list`);
      }
    });
  };

  acceptRequest = ({ groupId, requestId }) => {
    const fetchOptions = {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        member: requestId
      }),
      headers: {
        "Content-Type": "application/json"
      }
    };
    //TODO: go through app and tie these to config file
    const destination = `${this.context.api.groups}/${groupId}/members`;

    sendRequest({
      destination,
      options: fetchOptions,
      success: () => {
        this.fetchGroups();
        this.context.router.history.push(`/groups/list`);
      },
      error: err => {
        console.error("error changing request", err);
        this.context.router.history.push(`/groups/list`);
      }
    });
  };

  removeMemberFromGroup = ({ groupId, memberId }) => {
    const fetchOptions = {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    };
    //TODO: go through app and tie these to config file
    const destination = `${
      this.context.api.groups
    }/${groupId}/members/${memberId}`;

    sendRequest({
      destination,
      options: fetchOptions,
      success: () => {
        this.fetchGroups();
        this.context.router.history.push(`/groups/list`);
      },
      error: err => {
        console.error("error removing member", err);
      }
    });
  };

  /**
   * Handle user clicking the add group button
   * Show the add group dialog
   */
  addGroupClickHandler = () => {
    this.setState({
      addGroupDialogOpen: true
    });
  };

  /**
   * Handle the dissmissal of the add group dialog
   * sets open = false
   */
  addGroupDialogDismissHandler = () => {
    this.setState({
      addGroupDialogOpen: false
    });
  };

  /**
   * Handle the submission of the add group dialog
   * Create a group with the given players and the specified challenge
   */
  addGroupDialogSubmitHandler = () => {
    this.setState({
      addGroupDialogOpen: false
    });
    setTimeout(() => this.fetchGroups(), 100);
  };

  deleteGroup = ({ groupId }) => {
    const destination = `${this.context.api.groups}/${groupId}`;
    const fetchOptions = {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    };
    sendRequest({
      destination,
      options: fetchOptions,
      success: r => {
        this.fetchGroups();
        this.context.router.history.push(`/groups/list`);
      },
      error: e => {
        console.error(e);
        this.fetchGroups();
        this.context.router.history.push(`/groups/list`);
      }
    });
  };

  render() {
    const { match } = this.props;

    const { url: currentUrl } = match;
    const { groups, addGroupDialogOpen } = this.state;

    const tableData = getTableData(groups, this.deleteGroup, match.url);

    return (
      <div>
        {renderAddGroupButton(this.addGroupClickHandler)}
        <GroupTable
          table={tableData.table}
          buttons={[
            <Button onClick={this.fetchGroups}>
              <UpdateIcon />
            </Button>
          ]}
          headers={tableData.headers}
        />
        <AddGroupDialog
          open={addGroupDialogOpen}
          handleDismissClick={this.addGroupDialogDismissHandler}
          handleSubmitClick={this.addGroupDialogSubmitHandler}
        />
        <Route
          path={`${currentUrl}/:groupId/members/:memberId`}
          render={({ match }) => (
            <div>
              <Button
                // backgroundColor={socGray1}
                onClick={() => this.context.router.history.push(`/groups/list`)}
              >
                Cancel <CancelIcon color={socWhite1} />
              </Button>
              {match.params.groupId}
              {match.params.memberId}
              <Button
                // backgroundColor={socRedError}

                onClick={() => this.removeMemberFromGroup(match.params)}
              >
                Remove <ConfirmIcon color={socWhite1} />
              </Button>
            </div>
          )}
        />
        <Route
          path={`${currentUrl}/:groupId/requests/:requestId`}
          render={({ match }) => (
            <div>
              <Button
                // backgroundColor={socGreenPass}
                onClick={() => this.acceptRequest(match.params)}
              >
                Accept <ConfirmIcon color={socWhite1} />
              </Button>
              {match.params.groupId}
              {match.params.requestId}
              <Button
                // backgroundColor={socRedError}
                onClick={() => this.declineRequest(match.params)}
              >
                Decline <CancelIcon color={socWhite1} />
              </Button>
            </div>
          )}
        />
        <Route
          path={`${currentUrl}/:groupId/delete`}
          render={({ match }) => (
            <div>
              <Button
                // backgroundColor={socGray1}

                onClick={() => this.context.router.history.push(`/groups/list`)}
              >
                Cancel <CancelIcon color={socWhite1} />
              </Button>
              {match.params.groupId}
              <Button
                // backgroundColor={socRedError}

                onClick={() => this.deleteGroup(match.params)}
              >
                Delete <ConfirmIcon color={socWhite1} />
              </Button>
            </div>
          )}
        />
      </div>
    );
  }
}

GroupTableContainer.contextTypes = {
  router: PropTypes.object,
  routes: PropTypes.object,
  api: PropTypes.object
};

export default GroupTableContainer;
