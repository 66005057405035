import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { constants } from '@school-of-code/soc-redux';

import css from './TextInputDialog.module.css';

const {
  ModalTypes: { SAVE_CHARACTER, LOAD_CHARACTER }
} = constants;

const displays = {
  character: {
    title: 'Character Chooser',
    text: 'Choose your character name'
  },
  file: {
    title: 'File Chooser',
    text: 'Choose your file name'
  }
};

const TextInputDialog = ({
  open,
  name = '',
  chosen,
  filter,
  children,
  onSubmit,
  onRequestClose,
  onTextChange,
  scrollable = false,
  inputError,
  type = 'character',
  modalType
}) => {
  const actions = [
    <Button variant="contained" onClick={onRequestClose}>
      Cancel
    </Button>,
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        switch (modalType) {
          case LOAD_CHARACTER:
            return chosen && onSubmit(chosen);
          case SAVE_CHARACTER:
          default:
            return name && onSubmit(name);
        }
      }}
    >
      Submit
    </Button>
  ];

  return (
    <Dialog
      actions={actions}
      modal
      //TODO: default to stop warning, investigate reason (not set somewhere)
      open={open || false}
      onClose={onRequestClose}
      PaperProps={{ classes: { root: css.paper } }}
    >
      <DialogTitle disableTypography classes={{ root: css.title }}>
        {displays[type].title}
      </DialogTitle>
      <DialogContent classes={{ root: css.content }}>
        <div className={css.textfieldContainer}>
          <TextField
            error={Boolean(inputError)}
            placeholder="grumpy"
            label={inputError || displays[type].text}
            value={name}
            onChange={e => onTextChange(e.target.value)}
            className={css.textfield}
          />
        </div>
        <div className={css.files}>{children}</div>
      </DialogContent>
      <DialogActions classes={{ root: css.actions }}>{actions}</DialogActions>
    </Dialog>
  );
};

export default TextInputDialog;
