import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Message from '../Message';
import css from '../ChatWindow.module.css';

export default class MessageList extends Component {
  scrollToBottom = () => {
    const node = ReactDOM.findDOMNode(this.messagesEnd);
    node.scrollIntoView({ behavior: 'smooth' });
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  render() {
    const { messages, thisPlayerId } = this.props;
    const messageNodes = messages.map((message, i) => {
      return (
        <li key={i}>
          <Message
            playerName={message.playerName}
            playerId={message.playerId}
            thisPlayerId={thisPlayerId}
          >
            {message.text}
          </Message>
        </li>
      );
    });
    return (
      <div>
        <ul className={css.chatWindowMessageList}>{messageNodes}</ul>
        <div
          className={css.messageListDiv}
          ref={el => {
            this.messagesEnd = el;
          }}
        />
      </div>
    );
  }
}
