import React from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import { Provider } from 'react-redux';
import io from 'socket.io-client';
import { ThemeProvider } from '@material-ui/core/styles';
import { store } from '@school-of-code/soc-redux';
import './index.css';
import './style/react-style.css';

import SoCContextProvider from './components/global-components/SocContextProvider';
import socMuiTheme from './style/socMuiTheme';
import awsUrls from './libs/server/aws-urls';
import * as serviceWorker from './serviceWorker';

import Root from './containers/Root';

// import addTheme from './hoc/addTheme';
import addModals from './hoc/addModals';
import withMuliplayerMessaging from './hoc/withMultiplayerMessaging';
import config from './config/config.js';

import { create } from 'jss';
import {
  StylesProvider,
  createGenerateClassName,
  jssPreset
} from '@material-ui/core/styles';

const { configureStore } = store;

const socket = io(config.serviceUrls.game.url);

const hocList = [
  // addTheme,
  addModals,
  withMuliplayerMessaging(socket)
];

const enhance = compose(...hocList);

const EnhancedRoot = enhance(Root);

// put all routes here
const props = {
  serviceUrls: config.serviceUrls,
  awsUrls,
  routes: config.routes,
  api: config.serviceUrls.api
};

const initialState = {};
const gameStore = configureStore(initialState, props.serviceUrls, socket);

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: document.getElementById('jss-insertion-point')
});

ReactDOM.render(
  <Provider store={gameStore}>
    <StylesProvider jss={jss} generateClassName={generateClassName}>
      <SoCContextProvider
        routes={props.routes}
        api={props.api}
        aws={props.awsUrls}
      >
        <ThemeProvider theme={socMuiTheme}>
          <EnhancedRoot socket={socket} {...props} />
        </ThemeProvider>
      </SoCContextProvider>
    </StylesProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
