import React from 'react';

import PeopleIcon from '@material-ui/icons/People';
import MinimiseIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/IconButton';

import css from '../ChatWindow.module.css';

const makeButton = (Icon, options = {}) => {
  const { onTouchTap } = options;
  if (onTouchTap)
    return (
      <Button className={css.headerButton} onClick={onTouchTap}>
        {Icon}
      </Button>
    );
  return <Button className={css.headerButton}>{Icon}</Button>;
};

export default ({
  roomName,
  numberOfConnectedPlayers,
  onMinimise,
  onMaximise,
  unseenMessages
}) => (
  <div className={css.chatWindowMessageHeader}>
    <div className={css.chatHeaderInfo}>
      <div className={css.iconGroup}>
        <span>{roomName}</span>
      </div>
      <div className={css.iconGroup}>
        {makeButton(<PeopleIcon />)}
        <span>{numberOfConnectedPlayers}</span>
      </div>
    </div>
    <div className={css.chatHeaderControl}>
      {makeButton(<MinimiseIcon className={css.icon} />, {
        onTouchTap: onMinimise
      })}
    </div>
  </div>
);
