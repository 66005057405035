import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { connect } from 'react-redux';
import { actions } from '@school-of-code/soc-redux';
import ProfileHeader from '../profile-page/profile-header';
import ProfileInformationArea from '../profile-page/profile-information-area';
import ContentBox from '../player-info/ContentBox';
import HorizontalImageSlider from '../global-components/HorizontalImageSlider';
import ChooseACharacter from '../global-components/ChooseACharacter';

import './profile-page.css';
const { modals: ModalsActions } = actions;

const container = {
  width: '80%',
  margin: 'auto'
};

const skillsSliderSettings = {
  arrows: false,
  dots: false,
  slidesToShow: 3
};

const skillSliderImages = [
  {
    url: '/images/html.png',
    label: 'HTML'
  },
  {
    url: '/images/css.png',
    label: 'CSS'
  },
  {
    url: '/images/js.png',
    label: 'JavaScript'
  }
];
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: 0 };
  }
  componentDidMount() {
    this.props.loadCharacters();
  }
  onSelect = i => this.setState(() => ({ selected: i }));
  handleChangeIndex = index => {
    this.setState({
      index
    });
  };

  render() {
    const {
      username,
      avatarUrl,
      avatarEditUrl,
      characters = [],
      stats
    } = this.props;
    const {
      getUserCharacterSvgUrl,
      routes,
      defaultCharacterPngUrl
    } = this.context;
    const { selected, index } = this.state;
    const styles = {
      root: {
        padding: '0 66% 0 0',
        slideContainer: {
          padding: '0 20% 0 0 '
        }
      }
    };
    return (
      <div
        style={{
          backgroundImage: 'url(/images/patterns/stars.png)',
          backgroundSize: 'contain',
          padding: '1rem'
        }}
      >
        <ProfileHeader
          width="100%"
          height="200px"
          avatarUrl={avatarUrl}
          avatarEditUrl={avatarEditUrl}
        />
        <div style={container}>
          <ProfileInformationArea
            username={username}
            width="80%"
            innerAlign="center"
            padding="10px 0px 20px 0px"
            marginTop="70px"
            stats={stats}
          />
          <ContentBox
            width="100%"
            align="auto"
            boxArrows="none"
            text="Characters"
          >
            <AutoPlaySwipeableViews
              index={index}
              onChangeIndex={this.handleChangeIndex}
              style={styles.root}
              slideStyle={styles.slideContainer}
            >
              {characters &&
                characters.map((character, i) => {
                  const url = (
                    getUserCharacterSvgUrl({
                      user: username,
                      character
                    }) || defaultCharacterPngUrl
                  ).replace(/ /g, '%20');
                  const flappyUrl = `${
                    routes.floppyCharacter
                  }?characterUrl=${encodeURIComponent(
                    getUserCharacterSvgUrl({
                      user: username,
                      character
                    })
                  )}`;
                  return (
                    <div>
                      <ChooseACharacter
                        key={i}
                        url={url}
                        name={character}
                        flappyUrl={flappyUrl}
                        onSelect={() => this.onSelect(i)}
                        selected={selected === i}
                      />
                    </div>
                  );
                })}
            </AutoPlaySwipeableViews>
          </ContentBox>
          <ContentBox
            display="inline-block"
            width="100%"
            height="30vh"
            align="auto"
            margin="20px"
            text="Skills"
          >
            <HorizontalImageSlider
              sliderSettings={skillsSliderSettings}
              sliderImages={skillSliderImages}
              width="100%"
            />
          </ContentBox>
        </div>
      </div>
    );
  }
}

Profile.contextTypes = {
  getUserCharacterSvgUrl: PropTypes.func,
  routes: PropTypes.object,

  defaultCharacterPngUrl: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    characters: state.modals.modalProps.options,
    username: state.meta.username
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadCharacters: () => {
      dispatch(ModalsActions.loadCharacters());
    },
    dispatch: dispatch
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
