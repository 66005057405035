import React from "react";
import { connect } from "react-redux";

import { actions, selectors } from "@school-of-code/soc-redux";

import Feedback from "../../global-components/Feedback";

const { meta: MetaActions, modals: ModalsActions } = actions;
const {
  game: { selectRoomId, selectThisPlayerName },
  meta: { selectIsFeedbackSubmitted }
} = selectors;

const FeedbackModal = ({ onSubmit, onClose, show }) => (
  <Feedback open={show} handleSubmitClick={onSubmit} onRequestClose={onClose} />
);

function mapStateToProps(state, ownProps) {
  return {
    roomId: selectRoomId(state),
    playerName: selectThisPlayerName(state),
    show: !selectIsFeedbackSubmitted(state),
    ...ownProps
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onClose: () => {
      dispatch(ModalsActions.hideModal());
    },
    dispatch
  };
};

const mergeProps = (stateProps, dispatchProps) => {
  const { dispatch } = dispatchProps;
  const { roomId, playerName } = stateProps;
  return {
    ...stateProps,
    ...dispatchProps,
    onSubmit: (netPromoterScore, enjoymentScore) => {
      dispatch(ModalsActions.hideModal());
      dispatch(
        MetaActions.submitFeedback(
          roomId,
          playerName,
          netPromoterScore,
          enjoymentScore
        )
      );
      //submit the feedback.
      //Which is an async action, api call to server
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(FeedbackModal);
