import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { getValidationText } from '../../../libs/client/validator';
import css from '../Form.module.css';

class LoginForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      username: '',
      password: '',
      errorText: '',
      usernameErrorText: '',
      passwordErrorText: ''
    };
  }

  handleUsernameChange = event => {
    this.setState({
      username: event.target.value.toLowerCase(),
      usernameErrorText: getValidationText(event.target.value, 'username')
    });
  };

  handlePasswordChange = event => {
    return this.setState({
      password: event.target.value
    });
  };

  submitForm = e => {
    e.preventDefault();
    const { onSubmit } = this.props;
    const { username, password } = this.state;
    if (!this.checkFormReady()) {
      return;
    }
    return onSubmit({
      username,
      password
    });
  };

  checkFormReady = () => {
    const {
      username,
      password,
      usernameErrorText,
      passwordErrorText
    } = this.state;
    let valueArray = [username, password];
    if (valueArray.filter(c => c === '').length > 0) {
      this.setState({
        errorText: 'Fields cannot be left empty :('
      });
      return false;
    }
    let textArray = [usernameErrorText, passwordErrorText];
    if (textArray.filter(t => t !== '').length > 0) {
      this.setState({
        errorText: 'Fix the errors in the form first :)'
      });
      return false;
    }
    this.setState({
      errorText: ''
    });
    return true;
  };

  render() {
    const {
      username,
      password,
      errorText,
      usernameErrorText,
      passwordErrorText
    } = this.state;

    const { metaError } = this.props;
    return (
      <form onSubmit={this.submitForm}>
        <TextField
          id="username"
          value={username}
          onChange={this.handleUsernameChange}
          placeholder="e.g. coolcoder99"
          label="Username"
          fullWidth
          helperText={usernameErrorText}
          error={Boolean(usernameErrorText)}
        />
        <TextField
          id="password"
          value={password}
          onChange={this.handlePasswordChange}
          placeholder="Type in your password here"
          label="Password"
          type="password"
          fullWidth
          helperText={passwordErrorText}
          error={Boolean(passwordErrorText)}
        />
        <div className={css.error}>{metaError ? metaError : errorText}</div>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          className={css.button}
        >
          Login
        </Button>
      </form>
    );
  }
}

LoginForm.contextTypes = {
  routes: PropTypes.object,
  api: PropTypes.object
};

export default LoginForm;
