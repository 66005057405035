import React from 'react';
import { connect } from 'react-redux';

import { constants, selectors } from '@school-of-code/soc-redux';

import HintModal from '../HintModal';
import SaveChooserModal from '../SaveChooserModal';
import FeedbackModal from '../FeedbackModal';
import GoPlayModal from '../GoPlayModal';
import InvitePlayerModal from '../InvitePlayerModal';
import LoadChooserModal from '../LoadChooserModal';
import SignUpModal from '../SignUpModal';
import FriendsModal from '../FriendsModal';

const { ModalTypes } = constants;
const {
  modals: { selectModalType, selectModalProps }
} = selectors;

const MODAL_COMPONENTS = {
  [ModalTypes.SAVE_CHARACTER]: SaveChooserModal,
  [ModalTypes.HINT]: HintModal,
  [ModalTypes.FEEDBACK]: FeedbackModal,
  [ModalTypes.GO_PLAY]: GoPlayModal,
  [ModalTypes.INVITE_PLAYER]: InvitePlayerModal,
  [ModalTypes.LOAD_CHARACTER]: LoadChooserModal,
  [ModalTypes.SIGN_UP]: SignUpModal,
  [ModalTypes.FRIENDS]: FriendsModal
  /* other modals */
};

const ModalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    return null;
  }
  const SpecificModal = MODAL_COMPONENTS[modalType];

  return <SpecificModal {...modalProps} />;
};

const mapStateToProps = (state, ownProps) => ({
  modalType: selectModalType(state),
  modalProps: selectModalProps(state),
  ...ownProps
});

export default connect(mapStateToProps)(ModalRoot);
