import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import css from './RegisterDialog.module.css';

export default class RegisterDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usernameValue: '',
      passwordValue: '',
      confirmPasswordValue: '',
      emailValue: '',
      usernameErrorValue: '',
      passwordErrorValue: '',
      confirmPasswordErrorValue: '',
      emailErrorValue: '',
      message: ''
    };
  }

  handleUsernameChange = event => {
    this.setState({
      usernameValue: event.target.value
    });
  };

  handlePasswordChange = event => {
    this.setState({
      passwordValue: event.target.value
    });
  };

  handleConfirmPasswordChange = event => {
    this.setState({
      confirmPasswordValue: event.target.value
    });
  };

  handleEmailChange = event => {
    this.setState({
      emailValue: event.target.value
    });
  };

  handleSubmitClick = () => {
    const {
      usernameValue,
      passwordValue,
      confirmPasswordValue,
      emailValue
    } = this.state;

    const { handleSubmitClick } = this.props;

    //reset messages/errors
    this.setState({
      usernameErrorValue: '',
      passwordErrorValue: '',
      confirmPasswordErrorValue: '',
      emailErrorValue: '',
      message: ''
    });

    if (!usernameValue) {
      this.setState({
        usernameErrorValue: 'Please enter a username'
      });
    }
    if (!passwordValue) {
      this.setState({
        passwordErrorValue: 'Please enter a password'
      });
    }
    if (!confirmPasswordValue) {
      this.setState({
        confirmPasswordErrorValue: 'Please enter a password'
      });
    }
    if (!emailValue) {
      this.setState({
        emailErrorValue: 'Please enter an e-mail address'
      });
    }
    //validate username
    if (!usernameValue.match(/^[a-z0-9]+$/)) {
      this.setState({
        usernameErrorValue:
          'Invalid username - please only use lowercase letters and numbers'
      });
    }
    //validate passwords match
    if (passwordValue !== confirmPasswordValue) {
      //set password error text
      this.setState({
        passwordErrorValue: 'Passwords do not match',
        confirmPasswordErrorValue: 'Passwords do not match'
      });
      return;
    }
    if (
      !usernameValue ||
      !passwordValue ||
      !confirmPasswordValue ||
      !emailValue
    ) {
      return;
    }
    //validate email
    //regex from http://emailregex.com/
    if (
      !emailValue.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      this.setState({
        emailErrorValue: 'Please enter a valid email address'
      });
      return;
    }
    if (!handleSubmitClick) {
      return;
    }
    const registerInfo = {
      username: usernameValue,
      password: passwordValue,
      confirmPassword: confirmPasswordValue,
      email: emailValue
    };
    handleSubmitClick(registerInfo);
  };

  render() {
    const { open, handleDismissClick } = this.props;
    const actions = [
      <Button onClick={handleDismissClick}>Cancel </Button>,
      <Button color="primary" onClick={this.handleSubmitClick}>
        Submit
      </Button>
    ];

    return (
      <Dialog
        title="Please register to save your character"
        open={open}
        onClose={handleDismissClick}
      >
        <TextField
          placeholder="Username"
          label="Username"
          onChange={this.handleUsernameChange}
        />
        <br />
        <TextField
          placeholder="Password"
          label="Password"
          value={this.state.passwordValue}
          onChange={this.handlePasswordChange}
        />
        <br />
        <TextField
          placeholder="Confirm Password"
          label="Confirm Password"
          value={this.state.confirmPasswordValue}
          onChange={this.handleConfirmPasswordChange}
        />
        <br />
        <TextField
          placeholder="Email address"
          label="Email address"
          onChange={this.handleEmailChange}
        />
        <br />
        <h4 className={css.messageStyle}>{this.state.message}</h4>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
}
