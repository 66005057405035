import React, { Component } from "react";

import ToolboxIcon from "@material-ui/icons/Brush";
import IconButton from "@material-ui/core/IconButton";
import Toolbelt from "../Toolbelt";

class ToolbeltContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  onRequestChange = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  render() {
    const { open } = this.state;
    return (
      <div>
        <IconButton onClick={this.onRequestChange}>
          <ToolboxIcon />
        </IconButton>
        {open ? <Toolbelt open onRequestChange={this.onRequestChange} /> : null}
      </div>
    );
  }
}

export default ToolbeltContainer;
