/**
 * Created by Chris on 04/01/2016.
 */

export const validatePasswordMatch = (password, confirmPassword) => {
  return password === confirmPassword;
};

export const validateUsername = username => {
  const validRegex = /^[a-z0-9]+$/;
  return username.match(validRegex);
};

export const validateEmail = email => {
  return /\S+@\S+\.\S+/.test(email);
};
/**
 * Function takes a password value and compared again REGEX expression to
 * ensure that password is > 8 characters & have one alphabetical character
 * and one numerical value.
 * @param password - password to validate
 * @returns {Boolean} whether or not password is valid
 */
export const validatePassword = password => {
  const validPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
  return password.match(validPassword);
};

export const getValidationText = (toCheck, type) => {
  if (toCheck === '') {
    return 'Cannot be left empty';
  }
  switch (type) {
    case 'username':
      return validateUsername(toCheck)
        ? ''
        : 'Invalid username - please only use lowercase letters and numbers';
    case 'email':
      return validateEmail(toCheck) ? '' : 'Please enter a valid email address';
    case 'password':
      return validatePassword(toCheck)
        ? ''
        : 'Password must be more than 8 letters and contain at least 1 number';
    default:
      return '';
  }
};
