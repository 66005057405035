import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actions, selectors } from '@school-of-code/soc-redux';

import { sendRequest } from '../libs/utils/requests';

import InformationArea from '../components/information/InformationArea';
import PlayersArea from '../components/player-window/PlayersArea';

const { game: GameActions } = actions;
const {
  game: {
    selectConnectedStates,
    selectCurrentTask,
    selectThisPlayerCurrentInfo,
    selectPlayerNames,
    selectThisPlayerId,
    selectElapsed,
    selectRoomSize
  },
  challenge: { selectChallengeName }
} = selectors;

class Game extends Component {
  //TODO: needs to be in redux and in flow of rest of app
  componentDidMount() {
    const { match, setRoomId, onPlayerJoinRoom } = this.props;
    const { params = {} } = match;
    const { gameId = 'default' } = params;
    setRoomId(gameId);
    const destination = `${this.context.api.rooms}/${gameId}/status/join`;
    const fetchOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    sendRequest({
      destination,
      options: fetchOptions,
      success: () => {
        onPlayerJoinRoom(gameId);
      },
      error: err => console.error('Could Not Join Game', err)
    });
  }

  render() {
    const {
      roomSize,
      playerNames,
      thisPlayerId,
      challengeName,
      task,
      playerInformation,
      connectedStates
    } = this.props;
    return (
      <div>
        <div className="game_container">
          <div className="information_area fill-height">
            <InformationArea
              taskTitle={task && task.title}
              taskInformation={task && task.information}
              playerInformation={playerInformation}
            />
          </div>
          <PlayersArea
            size={roomSize}
            playerNames={playerNames}
            playerId={thisPlayerId}
            connectedStates={connectedStates}
            challengeName={challengeName}
          />
        </div>
      </div>
    );
  }
}

Game.contextTypes = {
  api: PropTypes.object
};

Game.propTypes = {
  elapsed: PropTypes.number.isRequired,
  roomSize: PropTypes.number.isRequired,
  playerNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  thisPlayerId: PropTypes.number.isRequired,
  challengeName: PropTypes.string,
  task: PropTypes.shape({
    title: PropTypes.string,
    information: PropTypes.shape({
      description: PropTypes.string,
      instructions: PropTypes.string
    })
  }),
  playerInfo: PropTypes.shape({
    solution: PropTypes.array,
    skeleton: PropTypes.array,
    tests: PropTypes.array,
    imports: PropTypes.array,
    languages: PropTypes.arrayOf(PropTypes.string),
    information: PropTypes.shape({
      instructions: PropTypes.string
    })
  }),
  connectedStates: PropTypes.arrayOf(PropTypes.bool)
};

Game.defaultProps = {
  elapsed: 0,
  task: {
    information: {
      description:
        "We are now loading your challenge, you'll be up and learning in no time"
    }
  }
};

function mapStateToProps(state, ownProps) {
  const currentTask = selectCurrentTask(state);
  return {
    elapsed: selectElapsed(state),
    roomSize: selectRoomSize(state),
    playerNames: selectPlayerNames(state),
    thisPlayerId: selectThisPlayerId(state),
    challengeName: selectChallengeName(state),
    task: currentTask,
    playerInfo: currentTask && selectThisPlayerCurrentInfo(state),
    connectedStates: selectConnectedStates(state),
    ...ownProps
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleSetElapsed: elapsed => dispatch(GameActions.setElapsed(elapsed)),
    setRoomId: id => dispatch(GameActions.setRoomId(id)),
    dispatch
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Game);
