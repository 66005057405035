import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import InvitePlayer from '../../global-components/InvitePlayer';
import ToolbeltContainer from '../../global-components/ToolbeltContainer';

import { constants } from '@school-of-code/soc-redux';
import {
  socStatusNeutral,
  socStatusRed,
  socStatusGreen,
  socStatusOrange
} from '../../../style/soc-colors';
import css from './PlayerHeader.module.css';

const {
  Misc: { EMPTY_PLAYER_SLOT },
  PlayerStatuses
} = constants;

const PlayerHeader = ({ mine, playerName, playerStatus, isConnected }) => {
  //include RUNNING colour too
  //Do this as switch/case statement
  var statusBackgroundColor = socStatusNeutral;

  switch (playerStatus) {
    case PlayerStatuses.FAILED:
      statusBackgroundColor = socStatusRed;
      break;
    case PlayerStatuses.PASSED:
    case PlayerStatuses.DONE:
      statusBackgroundColor = socStatusGreen;
      break;
    case PlayerStatuses.READY_FOR_NEXT:
    case PlayerStatuses.RUNNING:
      statusBackgroundColor = socStatusOrange;
      break;
    default:
      break;
  }

  const progressStyle = {
    visibility: playerStatus === PlayerStatuses.RUNNING ? 'visible' : 'hidden'
  };

  const playerNameValue = (playerName, isConnected) => {
    return !isConnected || playerName === EMPTY_PLAYER_SLOT
      ? '...'
      : playerName;
  };

  return (
    <div className={classnames(css.areaHeading, css.playerHeader)}>
      {mine ? <ToolbeltContainer /> : null}
      <div className={css.center}>
        <span className={css.playerHeaderName}>
          {playerNameValue(playerName, isConnected)}
        </span>
        <Button
          className={css.status}
          style={{ backgroundColor: statusBackgroundColor }}
          disabled
        >
          {playerStatus}
        </Button>
        <CircularProgress size={0.5} style={progressStyle} />
      </div>
      <InvitePlayer />
    </div>
  );
};
PlayerHeader.propTypes = {
  playerName: PropTypes.string.isRequired,
  playerStatus: PropTypes.string,
  /**
   * Is this player currently connected to the game or not?
   */
  isConnected: PropTypes.bool
};
PlayerHeader.defaultProps = {
  playerName: '...',
  playerStatus: PlayerStatuses.DEFAULT,
  isConnected: false
};

export default PlayerHeader;
