import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CharacterList from '../CharacterList';

import css from './Pregame.module.css';

class Pregame extends Component {
  render() {
    return (
      <div className={css.page}>
        <div className={css.container}>
          <div className={css.header}>Your pixel characters</div>
          <div className={css.caption}>
            Select an avatar from your library to use it in your next adventure!
          </div>
          <CharacterList {...this.context} />
        </div>
      </div>
    );
  }
}

Pregame.contextTypes = {
  routes: PropTypes.object,
  getUserCharacterSvgUrl: PropTypes.func,
  defaultCharacterPngUrl: PropTypes.string
};

export default Pregame;
